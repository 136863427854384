<div [hidden]="hideComponent" [formGroup]="form">
  <div formGroupName="transactionInformation">
    <mat-label class="tw-block tw-h-7 tw-leading-7 tw-pb-2" [hidden]="hideLabel">Slide right all that apply:</mat-label>

    <div [hidden]="!config.attShortSale.visible" class="tw-flex tw-items-center tw-pb-2">
      <mat-slide-toggle formControlName="attShortSale" class="tw-h-auto tw-mr-4"></mat-slide-toggle>
      <span class="tw-leading-5">Transaction involves a Short Sale</span>
    </div>

    <div [hidden]="!config.attReo.visible" class="tw-flex tw-items-center tw-pb-2">
      <mat-slide-toggle formControlName="attReo" class="tw-h-auto tw-mr-4"></mat-slide-toggle>
      <span class="tw-leading-5">Subject property owned by a lender (REO)</span>
    </div>

    <div [hidden]="!config.attNewLoan.visible" class="tw-flex tw-items-center tw-pb-2">
      <mat-slide-toggle formControlName="attNewLoan" class="tw-h-auto tw-mr-4"></mat-slide-toggle>
      <span class="tw-leading-5">Buyer is obtaining a new loan</span>
    </div>

    <div [hidden]="!config.attFirpta.visible" class="tw-flex tw-items-center tw-pb-2">
      <mat-slide-toggle formControlName="attFirpta" class="tw-h-auto tw-mr-4"></mat-slide-toggle>
      <span class="tw-leading-5">Seller is a U.S. Citizen (pursuant to FIRPTA requirements)</span>
    </div>

    <div [hidden]="!config.attPersonalResidence.visible" class="tw-flex tw-items-center tw-pb-2">
      <mat-slide-toggle formControlName="attPersonalResidence" class="tw-h-auto tw-mr-4"></mat-slide-toggle>
      <span class="tw-leading-5">Buyer is acquiring the property as their personal residence</span>
    </div>

    <div [hidden]="!config.attFtb.visible" class="tw-flex tw-items-center tw-pb-2">
      <mat-slide-toggle formControlName="attFtb" class="tw-h-auto tw-mr-4"></mat-slide-toggle>
      <span class="tw-leading-5">Property is the principal residence of the seller</span>
    </div>

    <div [hidden]="!config.propertyIncorporatedArea.visible" class="tw-flex tw-items-center tw-pb-2">
      <mat-slide-toggle formControlName="propertyIncorporatedArea" class="tw-h-auto tw-mr-4"></mat-slide-toggle>
      <span class="tw-leading-5">Within the City Limits</span>
    </div>

    <div [hidden]="!config.attOwnersExemption.visible" class="tw-flex tw-items-center tw-pb-2">
      <mat-slide-toggle formControlName="attOwnersExemption" class="tw-h-auto tw-mr-4"></mat-slide-toggle>
      <span class="tw-leading-5">Buyer is eligible for a county homeowner's exemption on property tax</span>
    </div>

    <div [hidden]="!config.attHarpta.visible" class="tw-flex tw-items-center tw-pb-2">
      <mat-slide-toggle formControlName="attHarpta" class="tw-h-auto tw-mr-4"></mat-slide-toggle>
      <span class="tw-leading-5">Seller is a resident of Hawaii</span>
    </div>

    <div [hidden]="!config.attSurveyAmendmentSplit.visible" class="tw-flex tw-items-center tw-pb-2">
      <mat-slide-toggle formControlName="attSurveyAmendmentSplit" class="tw-h-auto tw-mr-4"></mat-slide-toggle>
      <span class="tw-leading-5">Seller is paying for the Survey Amendment</span>
    </div>

    <mat-radio-group
      [hidden]="!config.attSubEscrow.visible"
      formControlName="attSubEscrow"
      class="tw-flex tw-flex-col tw-pb-2"
    >
      <mat-label class="tw-h-7 tw-leading-7">Services to be performed by Old Republic Title:</mat-label>
      <mat-radio-button class="tw-h-7 tw-leading-7" [value]="false">Primary Escrow Services</mat-radio-button>
      <mat-radio-button class="tw-h-7 tw-leading-7" [value]="true"
        >Secondary Escrow Services (Sub-Escrow)</mat-radio-button
      >
    </mat-radio-group>

    <mat-radio-group
      [hidden]="!config.attOwnersPolicySplit.visible"
      formControlName="attOwnersPolicySplit"
      class="tw-flex tw-flex-col tw-pb-2"
    >
      <mat-label class="tw-h-7 tw-leading-7">Is paying for the Owner's Policy:</mat-label>
      <mat-radio-button class="tw-h-7 tw-leading-7" value="Buyer">Buyer</mat-radio-button>
      <mat-radio-button class="tw-h-7 tw-leading-7" value="Seller">Seller</mat-radio-button>
    </mat-radio-group>
  </div>
</div>
