import { Component, OnInit } from '@angular/core';
import { NetsheetConfigService } from 'netsheet-calculator';

import { saveAs } from 'file-saver';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  constructor(private netsheetConfigService: NetsheetConfigService) {}

  ngOnInit(): void {
    const userAgent = navigator.userAgent;
    const device = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)
      ? 'mobile'
      : 'desktop';

    this.netsheetConfigService.setDevice(device);
    this.netsheetConfigService.setColors('#003087', '#DD971B');
  }

  onExport(file: any): void {
    saveAs(file.blob, file.name);
  }
}
