import { Injectable } from '@angular/core';

import { each, has } from 'lodash-es';
import { TinyColor } from '@ctrl/tinycolor';

export interface Color {
  hex: string;
  isDark: boolean;
  name: string;
}

interface ThemeColors {
  danger: string;
  primary: string;
  accent: string;
  light: string;
  dark: string;
  info: string;
  success: string;
  warn: string;
}


@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  colors: ThemeColors = {
    danger: '#EE3C37',
    primary: '#003B3F',
    accent: '#B0B1C1',
    dark: '#363949',
    success: '#018626',
    info: '#6E6D6D',
    light: '#FFFFFF',
    warn: '#EC9F24'
  };

  /**
   * Method setting the application specific colors.
   */
  setColors(colors: Partial<ThemeColors>): void {
    each(colors, (value, key) => {
      if (has(this.colors, key)) {
        const color = new TinyColor(value);
        if (color.isValid) {
          this.colors[key] = color.toHexString();
        }
      }
    });
    this.updateTheme();
  }

  /**
   * Method for updating the theme with current colors.
   */
  updateTheme(): void {
    each(this.colors, (value, key) => {
      this.updateThemeColor(key, value);
    });
  }

  /**
   * Method for updating the ionic theme color variable in DOM.
   */
  updateThemeColor(name: string, value: string): void {
    const palette = this.computeColors(value);
    if (['primary', 'accent', 'danger'].includes(name)) {
      palette.forEach((color) => {
        this.setDOMColor(`--theme-${name}-${color.name}`, color.hex);
        this.setDOMColor(`--theme-${name}-contrast-${color.name}`, color.isDark ? this.colors.light : this.colors.dark);
      });
    }
    this.setDOMColor(`--theme-${name}-500`, value);
  }

  /**
   * Method for computing the colors for the material theme.
   */
  computeColors(hex: string): Color[] {
    return [
      this.getColorObject(new TinyColor(hex).lighten(52), '50'),
      this.getColorObject(new TinyColor(hex).lighten(37), '100'),
      this.getColorObject(new TinyColor(hex).lighten(26), '200'),
      this.getColorObject(new TinyColor(hex).lighten(12), '300'),
      this.getColorObject(new TinyColor(hex).lighten(6), '400'),
      this.getColorObject(new TinyColor(hex), '500'),
      this.getColorObject(new TinyColor(hex).darken(6), '600'),
      this.getColorObject(new TinyColor(hex).darken(12), '700'),
      this.getColorObject(new TinyColor(hex).darken(18), '800'),
      this.getColorObject(new TinyColor(hex).darken(24), '900'),
      this.getColorObject(new TinyColor(hex).lighten(50).saturate(30), 'A100'),
      this.getColorObject(new TinyColor(hex).lighten(30).saturate(30), 'A200'),
      this.getColorObject(new TinyColor(hex).lighten(10).saturate(15), 'A400'),
      this.getColorObject(new TinyColor(hex).lighten(5).saturate(5), 'A700')
    ];
  }

  getColorObject(color: TinyColor, name: string): Color {
    return {
      hex: color.toHexString(),
      isDark: color.isDark(),
      name
    };
  }

  /**
   * Method for updating the theme color variable in DOM;
   */
  setDOMColor(name: string, value: string): void {
    document.documentElement.style.setProperty(name, value);
  }
}
