import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { PrefillService } from './prefill.service';

@Injectable({
  providedIn: 'root'
})
export class ResetService {
  private shouldReset: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private prefillService: PrefillService) {}

  reset(): void {
    this.prefillService.setDefaultPrefill();
    this.toggleReset(true);
  }

  toggleReset(value: boolean): void {
    this.shouldReset.next(value);
  }

  getShouldReset(): Observable<boolean> {
    return this.shouldReset.asObservable();
  }
}
