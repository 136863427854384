import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { OrganizationEnum } from './../enums/organization.enum';
import { OrganizationService } from './organization.service';

export interface WebServiceConfig {
  schema?: 'http' | 'https';
  domain?: string;
  port?: number;
  version?: string;
  securityHeader?: string;
  securityKey?: string;
}

export class ApiConfig {
  public netsheet: WebServiceConfig = {
    schema: 'http',
    domain: 'localhost',
    port: 3000,
    version: 'v1'
  };
  public report: WebServiceConfig = {
    schema: 'https',
    domain: 'netcalcs-report.herokuapp.com',
    version: 'v2',
    securityHeader: 'NETCALCS-SECURITY-KEY',
    securityKey: '1F32EE2E7AEC53A74B3B67F977A69'
  };
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  netsheetConfig: WebServiceConfig = {};

  reportConfig: WebServiceConfig = {};

  securityHeader: any;

  organization = OrganizationEnum.OLD_REPUBLIC_TITLE;

  constructor(
    private httpClient: HttpClient,
    private organizationService: OrganizationService,
    @Optional() config?: ApiConfig
  ) {
    if (config) {
      this.netsheetConfig = { ...this.netsheetConfig, ...config.netsheet };
      this.reportConfig = { ...this.reportConfig, ...config.report };
    }

    if (this.reportConfig.securityHeader && this.reportConfig.securityKey) {
      this.securityHeader = {};
      this.securityHeader[this.reportConfig.securityHeader] = this.reportConfig.securityKey;
    }

    this.organizationService.getOrganization().subscribe((organization: OrganizationEnum) => {
      this.organization = organization;
    });
  }

  getAPIURL(config: WebServiceConfig): string {
    let url = config.schema + '://' + config.domain;
    if (config.port) {
      url += ':' + config.port;
    }
    url = url + '/api/' + config.version;
    return url;
  }

  getAPIBaseURL(): string {
    return this.getAPIURL(this.netsheetConfig);
  }

  getReportAPIBaseURL(): string {
    return this.getAPIURL(this.reportConfig);
  }

  getOptions(options: any): any {
    if (!options.params) {
      options.params = new HttpParams();
    }
    options.params = options.params.append('organization', this.organization.toString());
    return { ...options, ...this.getHeaders() };
  }

  getHeaders(): object {
    const headers = {
      'Content-Type': 'application/json'
    };

    return {
      headers: new HttpHeaders(headers)
    };
  }

  get(path: string, options = {}): Observable<any> {
    return new Observable((observer) => {
      this.httpClient.get<any>(this.getAPIBaseURL() + path, this.getOptions(options)).subscribe(
        (response) => {
          observer.next(response);
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  post(path: string, data: any, options = {}): Observable<any> {
    return new Observable((observer) => {
      this.httpClient.post<any>(this.getAPIBaseURL() + path, data, this.getOptions(options)).subscribe(
        (response) => {
          observer.next(response);
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  downloadReport(data: any): Observable<any> {
    let headers = {
      'Content-Type': 'application/json'
    };

    if (this.securityHeader) {
      headers = { ...headers, ...this.securityHeader };
    }

    const options: any = {
      headers: new HttpHeaders(headers),
      responseType: 'blob'
    };

    return new Observable((observer) => {
      this.httpClient.post<any>(this.getReportAPIBaseURL() + '/report/pdf', data, options).subscribe(
        (response) => {
          observer.next(response);
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
}
