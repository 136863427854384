<ng-container *ngIf="errors">
  <ng-container *ngIf="errors['required']">
    <span>The field is required!</span>
  </ng-container>

  <ng-container *ngIf="errors['email']">
    <span>Email must be a valid address!</span>
  </ng-container>

  <ng-container *ngIf="errors['pattern']">
    <span>The field must have a valid format!</span>
  </ng-container>

  <ng-container *ngIf="errors['minlength']">
    <span>This field must contain a minimum of {{ errors.minlength.requiredLength }} characters!</span>
  </ng-container>

  <ng-container *ngIf="errors['maxlength']">
    <span>This field must contain a maximum of {{ errors.maxlength.requiredLength }} characters!</span>
  </ng-container>
</ng-container>
