<mat-card [hidden]="hideComponent" [formGroup]="form">
  <mat-card-content formGroupName="salesCommissions">
    <div class="tw-grid">
      <p class="tw-text-base tw-font-bold tw-mb-4">Sales Commissions</p>
      <hr class="tw-mb-4" />

      <mat-form-field [hidden]="!config.brokerCommissionTierRate.visible" class="tw-mb-1">
        <mat-label>First $100K Commission</mat-label>
        <input
          matInput
          formControlName="brokerCommissionTierRate"
          nscRateAmount="rate"
          type="text"
          inputmode="decimal"
          placeholder="%"
          autocomplete="off"
          [required]="config.brokerCommissionTierRate.required"
        />
        <mat-error *ngIf="salesCommissions?.get('brokerCommissionTierRate')?.errors">
          <nsc-validation-error class="tw-block" [errors]="salesCommissions?.get('brokerCommissionTierRate')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.brokerCommissionTotal.visible" class="tw-mb-1">
        <mat-label>{{ brokerCommissionTotalLabel }}</mat-label>
        <input
          matInput
          formControlName="brokerCommissionTotal"
          type="text"
          inputmode="brokerCommissionTotalInputMode"
          autocomplete="off"
          [nscRateAmount]="brokerCommissionTotalType"
          [placeholder]="brokerCommissionTotalPlaceholder"
          [required]="config.brokerCommissionTotal.required"
        />
        <mat-error *ngIf="salesCommissions?.get('brokerCommissionTotal')?.errors">
          <nsc-validation-error class="tw-block" [errors]="salesCommissions?.get('brokerCommissionTotal')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-label class="tw-block tw-h-7 tw-leading-7 tw-mb-2"> Slide right all that apply: </mat-label>

      <div [hidden]="!config.brokerCommissionSplits.visible" class="tw-flex tw-items-center">
        <mat-slide-toggle formControlName="brokerCommissionSplits" class="tw-h-auto tw-mr-4"></mat-slide-toggle>
        <span class="tw-leading-6">Show commission splits</span>
      </div>

      <div [hidden]="!config.brokerCommissionTaxTotal.visible" class="tw-flex tw-items-center">
        <mat-slide-toggle formControlName="brokerCommissionTaxTotal" class="tw-h-auto tw-mr-4"></mat-slide-toggle>
        <span class="tw-leading-6">Include GET</span>
      </div>

      <div [hidden]="!config.brokerCommissionTaxListing.visible" class="tw-flex tw-items-center">
        <mat-slide-toggle formControlName="brokerCommissionTaxListing" class="tw-h-auto tw-mr-4"></mat-slide-toggle>
        <span class="tw-leading-6">Include GET Listing Agent</span>
      </div>

      <div [hidden]="!config.brokerCommissionTaxSelling.visible" class="tw-flex tw-items-center">
        <mat-slide-toggle formControlName="brokerCommissionTaxSelling" class="tw-h-auto tw-mr-4"></mat-slide-toggle>
        <span class="tw-leading-6">Include GET Selling Agent</span>
      </div>

      <mat-form-field [hidden]="!config.brokerCommissionListing.visible" class="tw-mt-2 tw-mb-1">
        <mat-label> Listing Office Commission</mat-label>
        <input
          matInput
          formControlName="brokerCommissionListing"
          nscRateAmount="rateAmount"
          type="text"
          inputmode="text"
          placeholder="(percentage or amount)"
          autocomplete="off"
          [required]="config.brokerCommissionListing.required"
        />
        <mat-error *ngIf="salesCommissions?.get('brokerCommissionListing')?.errors">
          <nsc-validation-error class="tw-block" [errors]="salesCommissions?.get('brokerCommissionListing')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.brokerCommissionSelling.visible" class="tw-mb-1">
        <mat-label> Selling Office Commission</mat-label>
        <input
          matInput
          formControlName="brokerCommissionSelling"
          nscRateAmount="rateAmount"
          type="text"
          inputmode="text"
          placeholder="(percentage or amount)"
          autocomplete="off"
          [required]="config.brokerCommissionSelling.required"
        />
        <mat-error *ngIf="salesCommissions?.get('brokerCommissionSelling')?.errors">
          <nsc-validation-error class="tw-block" [errors]="salesCommissions?.get('brokerCommissionSelling')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
