<mat-card *ngIf="report" class="tw-mb-4">
  <mat-card-content>
    <div *ngIf="subtitles && subtitles.length">
      <p *ngFor="let subtitle of subtitles" class="tw-text-base tw-font-bold">{{ subtitle }}</p>
      <hr class="tw-mb-4" />
    </div>

    <div *ngIf="sections && sections.length" class="tw-mb-4">
      <div *ngFor="let section of sections; index as i" [ngClass]="i === sections.length - 1 ? 'tw-mb-0' : 'tw-mb-4'">
        <div class="tw-grid tw-grid-cols-12">
          <div class="tw-col-span-6">
            <p class="tw-text-base tw-font-bold">{{ section.title }}</p>
          </div>

          <ng-container *ngIf="i === 0">
            <div class="tw-col-span-3 tw-flex tw-justify-end tw-items-center">
              <p class="tw-text-base tw-font-bold">Debit</p>
            </div>

            <div class="tw-col-span-3 tw-flex tw-justify-end tw-items-center">
              <p class="tw-text-base tw-font-bold">Credit</p>
            </div>
          </ng-container>

          <hr class="tw-col-span-12" />
        </div>

        <div *ngIf="section.items && section.items.length">
          <div *ngFor="let item of section.items" class="tw-grid tw-grid-cols-12">
            <div class="tw-col-span-6">
              <p class="tw-inline-grid tw-text-sm tw-mr-2">{{ item.name }}</p>
              <p *ngIf="item.description" class="tw-inline-grid tw-text-xs tw-italic">({{ item.description }})</p>
            </div>

            <div class="tw-col-span-3 tw-flex tw-justify-end tw-items-center">
              <p class="tw-text-sm">{{ item.isCredit ? '' : item.value }}</p>
            </div>

            <div class="tw-col-span-3 tw-flex tw-justify-end tw-items-center">
              <p class="tw-text-sm">{{ item.isCredit ? item.value : '' }}</p>
            </div>

            <hr class="tw-col-span-12" />
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<div *ngIf="footer" class="tw-px-4 tw-mb-4">
  <div *ngIf="notes && notes.length">
    <p *ngFor="let note of notes" class="tw-text-xs tw-font-bold tw-mb-4">{{ note }}</p>
  </div>

  <div *ngIf="disclaimers && disclaimers.length">
    <p *ngFor="let disclaimer of disclaimers" class="tw-text-xs tw-mb-4">{{ disclaimer }}</p>
  </div>

  <div *ngIf="copyright">
    <p class="tw-text-xs">{{ copyright }}</p>
  </div>
</div>

<div *ngIf="error" class="tw-text-sm tw-text-center tw-tw-color-danger tw-mb-4">{{ error }}</div>

<div *ngIf="exportLoading" class="tw-mb-4">
  <mat-progress-bar mode="indeterminate" class="tw-rounded"></mat-progress-bar>
</div>

<div class="tw-text-right">
  <div class="tw-inline-block tw-mr-4">
    <button mat-raised-button color="primary" type="button" (click)="onBack()" [disabled]="exportLoading">Back</button>
  </div>

  <div class="tw-inline-block">
    <button mat-raised-button color="primary" type="button" (click)="onExport()" [disabled]="exportLoading">
      Export to PDF
    </button>
  </div>
</div>
