<form autocomplete="off" [formGroup]="form" (ngSubmit)="calculate(form.value)">
  <input type="hidden" autocomplete="false" />

  <nsc-customer-information class="tw-mb-4" [form]="form"></nsc-customer-information>
  <nsc-transaction-information class="tw-mb-4" [form]="form"></nsc-transaction-information>
  <nsc-mortgages class="tw-mb-4" [form]="form"></nsc-mortgages>
  <nsc-sales-commission class="tw-mb-4" [form]="form"></nsc-sales-commission>
  <nsc-taxes class="tw-mb-4" [form]="form"></nsc-taxes>
  <nsc-misc-items class="tw-mb-4" [form]="form"></nsc-misc-items>
  <nsc-user-information class="tw-mb-4" [form]="form"></nsc-user-information>

  <div *ngIf="errors && errors.length" class="tw-mb-4">
    <div *ngFor="let error of errors" class="tw-text-sm tw-text-center tw-color-danger">{{ error }}</div>
  </div>

  <div *ngIf="formLoading" class="tw-mb-4">
    <mat-progress-bar mode="indeterminate" class="tw-rounded"></mat-progress-bar>
  </div>

  <div class="tw-text-right">
    <div class="tw-inline-block tw-mr-4">
      <button mat-raised-button type="button" color="primary" (click)="reset()" [disabled]="formLoading">Reset</button>
    </div>

    <div class="tw-inline-block">
      <button mat-raised-button type="submit" color="primary" [disabled]="formLoading">Calculate</button>
    </div>
  </div>
</form>
