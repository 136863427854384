import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { QuestionnaireConfigService } from './../../services/questionnaire-config.service';
import { GenericComponent } from './../generic/generic.component';

@Component({
  selector: 'nsc-transaction-slider-information',
  templateUrl: './transaction-slider-information.component.html',
  styleUrls: ['./transaction-slider-information.component.scss']
})
export class TransactionSliderInformationComponent extends GenericComponent implements OnInit, OnChanges {
  @Input() form!: UntypedFormGroup;

  hideComponent = false;
  hideLabel = false;
  mainConfig: any = {};
  config: any = {
    attFirpta: { visible: true },
    attFtb: { visible: true },
    attHarpta: { visible: true },
    attNewLoan: { visible: true },
    attOwnersExemption: { visible: true },
    attOwnersPolicySplit: { visible: true },
    attPersonalResidence: { visible: true },
    attReo: { visible: true },
    attShortSale: { visible: true },
    attSubEscrow: { visible: true },
    attSurveyAmendmentSplit: { visible: true },
    propertyIncorporatedArea: { visible: true }
  };

  availableSouthernCaliforniaCounties: string[] = [
    'Imperial',
    'Kern',
    'Los Angeles',
    'Orange',
    'Riverside',
    'San Bernardino',
    'San Diego',
    'San Luis Obispo',
    'Santa Barbara',
    'Ventura'
  ];

  get customerInformation(): AbstractControl | null {
    return this.form?.get('customerInformation');
  }

  get transactionInformation(): AbstractControl | null {
    return this.form?.get('transactionInformation');
  }

  constructor(private questionnaireConfigService: QuestionnaireConfigService) {
    super();
  }

  ngOnInit(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;

    this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && this.form) {
      this.addUniqueSubscription(
        'property-state-change',
        this.transactionInformation?.get('propertyState')?.valueChanges.subscribe((state: string) => {
          this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
        })
      );

      this.addUniqueSubscription(
        'party-type-change',
        this.customerInformation?.get('partyType')?.valueChanges.subscribe(() => {
          this.updateAllCascade();
        })
      );

      this.addUniqueSubscription(
        'property-city-change',
        this.transactionInformation?.get('propertyCity')?.valueChanges.subscribe(() => {
          this.updateConfigByCity();
          this.onConfigUpdate(false);
        })
      );

      this.addUniqueSubscription(
        'escrow-county-change',
        this.transactionInformation?.get('escrowCounty')?.valueChanges.subscribe(() => {
          this.updateConfigByEscrowCounty();
          this.onConfigUpdate(false);
        })
      );

      this.addUniqueSubscription(
        'short-sale-change',
        this.transactionInformation?.get('attShortSale')?.valueChanges.subscribe((shortSale: boolean) => {
          if (shortSale) {
            this.transactionInformation?.get('attReo')?.setValue(false);
          }
        })
      );

      this.addUniqueSubscription(
        'reo-change',
        this.transactionInformation?.get('attReo')?.valueChanges.subscribe((reo: boolean) => {
          if (reo) {
            this.transactionInformation?.get('attShortSale')?.setValue(false);
          }
        })
      );

      this.addUniqueSubscription(
        'firpta-change',
        this.transactionInformation?.get('attFirpta')?.valueChanges.subscribe(() => {
          this.updateConfigByAttFirpta();
          this.onConfigUpdate(false);
        })
      );
    }
  }

  getConfigLoadSubscription(state: string): Subscription {
    return this.questionnaireConfigService.getConfigByState(state).subscribe((response) => {
      this.mainConfig = response;

      this.onConfigUpdate(true);
      this.updateAllCascade();
    });
  }

  updateAllCascade(): void {
    this.updateConfigByPartyType();
    this.updateConfigByCity();
    this.updateConfigByEscrowCounty();
    this.updateConfigByAttFirpta();
    this.onConfigUpdate(false);
  }

  updateConfigByPartyType(): void {
    const partyType = this.customerInformation?.get('partyType')?.value;

    switch (partyType) {
      case 'B':
        this.hideComponent = false;
        this.hideLabel = false;

        this.updateConfigByMainConfig([
          { key: 'attFirpta', visible: false },
          { key: 'attFtb', visible: false },
          { key: 'attHarpta', visible: false },
          { key: 'attNewLoan', visible: false },
          { key: 'attOwnersExemption', visible: true },
          { key: 'attOwnersPolicySplit', visible: true },
          { key: 'attPersonalResidence', visible: false },
          { key: 'attReo', visible: true },
          { key: 'attShortSale', visible: true },
          { key: 'attSubEscrow', visible: false },
          { key: 'attSurveyAmendmentSplit', visible: false },
          { key: 'propertyIncorporatedArea', visible: false }
        ]);

        break;
      case 'S':
        this.hideComponent = false;
        this.hideLabel = false;

        this.updateConfigByMainConfig([
          { key: 'attFirpta', visible: true },
          { key: 'attFtb', visible: true },
          { key: 'attHarpta', visible: true },
          { key: 'attNewLoan', visible: true },
          { key: 'attOwnersExemption', visible: true },
          { key: 'attOwnersPolicySplit', visible: true },
          { key: 'attPersonalResidence', visible: true },
          { key: 'attReo', visible: true },
          { key: 'attShortSale', visible: true },
          { key: 'attSubEscrow', visible: true },
          { key: 'attSurveyAmendmentSplit', visible: true },
          { key: 'propertyIncorporatedArea', visible: true }
        ]);

        break;
      case 'L': {
        const escrowState = this.transactionInformation?.get('escrowState')?.value;
        const escrowCounty = this.transactionInformation?.get('escrowCounty')?.value;

        if (escrowState === 'CA' && this.availableSouthernCaliforniaCounties.includes(escrowCounty)) {
          this.hideComponent = false;
          this.hideLabel = true;
        } else {
          this.hideComponent = true;
          this.hideLabel = false;
        }

        this.updateConfigByMainConfig([
          { key: 'attFirpta', visible: false },
          { key: 'attFtb', visible: false },
          { key: 'attHarpta', visible: false },
          { key: 'attNewLoan', visible: false },
          { key: 'attOwnersExemption', visible: false },
          { key: 'attOwnersPolicySplit', visible: false },
          { key: 'attPersonalResidence', visible: false },
          { key: 'attReo', visible: false },
          { key: 'attShortSale', visible: false },
          { key: 'attSubEscrow', visible: true },
          { key: 'attSurveyAmendmentSplit', visible: false },
          { key: 'propertyIncorporatedArea', visible: false }
        ]);

        break;
      }
    }
  }

  updateConfigByMainConfig(config: any): void {
    for (const item of config) {
      if (this.mainConfig[item.key].visible) {
        if (item.visible) {
          this.config[item.key] = { visible: item.visible, required: this.mainConfig[item.key].required };
        } else {
          this.config[item.key] = { visible: item.visible, required: false };
        }
      }
    }
  }

  updateConfigByCity(): void {
    const type = this.customerInformation?.get('partyType')?.value;
    const state = this.transactionInformation?.get('propertyState')?.value;
    const county = this.transactionInformation?.get('propertyCounty')?.value;
    const city = this.transactionInformation?.get('propertyCity')?.value;

    if (type === 'S' && state === 'CA' && county === 'Riverside' && city === 'Riverside') {
      this.config.propertyIncorporatedArea = {
        visible: true,
        required: this.mainConfig.propertyIncorporatedArea.required
      };
    } else {
      this.config.propertyIncorporatedArea = {
        visible: false,
        required: false
      };
    }
  }

  updateConfigByEscrowCounty(): void {
    const type = this.customerInformation?.get('partyType')?.value;
    const escrowState = this.transactionInformation?.get('escrowState')?.value;
    const escrowCounty = this.transactionInformation?.get('escrowCounty')?.value;

    if (escrowState === 'CA' && this.availableSouthernCaliforniaCounties.includes(escrowCounty)) {
      this.config.attSubEscrow = {
        visible: true,
        required: this.mainConfig.attSubEscrow.required
      };
    } else {
      this.config.attSubEscrow = {
        visible: false,
        required: false
      };
    }

    if (type === 'S' && escrowState === 'CA' && escrowCounty === 'Stanislaus') {
      this.config.attNewLoan = {
        visible: true,
        required: this.mainConfig.attNewLoan.required
      };
    } else {
      this.config.attNewLoan = {
        visible: false,
        required: false
      };
    }
  }

  updateConfigByAttFirpta(): void {
    const type = this.customerInformation?.get('partyType')?.value;
    const firpta = this.transactionInformation?.get('attFirpta')?.value;

    if (type === 'S' && this.config.attFirpta.visible && firpta === false) {
      this.config.attPersonalResidence = {
        visible: true,
        required: this.mainConfig.attPersonalResidence.required
      };
    } else {
      this.config.attPersonalResidence = { visible: false, required: false };
    }
  }

  onConfigUpdate(isMainConfigUpdated = false): void {
    for (const field of Object.keys(this.config)) {
      if (isMainConfigUpdated) {
        this.config[field] = this.mainConfig[field];
      }

      if (this.config[field].required) {
        this.transactionInformation?.get(field)?.setValidators([Validators.required]);
      } else {
        this.transactionInformation?.get(field)?.clearValidators();
      }
    }

    this.transactionInformation?.updateValueAndValidity({ emitEvent: false });
  }
}
