import { Component, OnChanges, OnInit, SimpleChanges, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { QuestionnaireConfigService } from './../../services/questionnaire-config.service';
import { GenericComponent } from './../generic/generic.component';

@Component({
  selector: 'nsc-sales-commission',
  templateUrl: './sales-commission.component.html',
  styleUrls: ['./sales-commission.component.scss']
})
export class SalesCommissionComponent extends GenericComponent implements OnInit, OnChanges {
  @Input() form!: UntypedFormGroup;

  hideComponent = false;
  mainConfig: any = {};
  config: any = {
    brokerCommissionListing: { visible: true },
    brokerCommissionSelling: { visible: true },
    brokerCommissionSplits: { visible: true },
    brokerCommissionTaxListing: { visible: true },
    brokerCommissionTaxSelling: { visible: true },
    brokerCommissionTaxTotal: { visible: true },
    brokerCommissionTierRate: { visible: true },
    brokerCommissionTotal: { visible: true }
  };

  brokerCommissionTotalInputMode = 'text';
  brokerCommissionTotalLabel = 'Sales Commission';
  brokerCommissionTotalPlaceholder = '(percentage or amount)';
  brokerCommissionTotalType: 'rate' | 'rateAmount' = 'rateAmount';

  get customerInformation(): AbstractControl | null {
    return this.form?.get('customerInformation');
  }

  get transactionInformation(): AbstractControl | null {
    return this.form?.get('transactionInformation');
  }

  get salesCommissions(): AbstractControl | null {
    return this.form?.get('salesCommissions');
  }

  constructor(private questionnaireConfigService: QuestionnaireConfigService) {
    super();
  }

  ngOnInit(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;

    this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && this.form) {
      this.addUniqueSubscription(
        'property-state-change',
        this.transactionInformation?.get('propertyState')?.valueChanges.subscribe((state: string) => {
          this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
        })
      );

      this.addUniqueSubscription(
        'party-type-change',
        this.customerInformation?.get('partyType')?.valueChanges.subscribe(() => {
          this.updateAllCascade();
        })
      );

      this.addUniqueSubscription(
        'splits-change',
        this.salesCommissions?.get('brokerCommissionSplits')?.valueChanges.subscribe(() => {
          this.updateConfigBySplits();
          this.onConfigUpdate(false);
        })
      );
    }
  }

  getConfigLoadSubscription(state: string): Subscription {
    return this.questionnaireConfigService.getConfigByState(state).subscribe((response) => {
      this.mainConfig = response;

      this.brokerCommissionTotalInputMode = state === 'OH' ? 'decimal' : 'text';
      this.brokerCommissionTotalLabel = state === 'OH' ? 'Balance of Commission' : 'Sales Commission';
      this.brokerCommissionTotalPlaceholder = state === 'OH' ? '%' : '(percentage or amount)';
      this.brokerCommissionTotalType = state === 'OH' ? 'rate' : 'rateAmount';

      this.onConfigUpdate(true);
      this.updateAllCascade();
    });
  }

  updateAllCascade(): void {
    this.updateConfigByPartyType();
    this.updateConfigBySplits();
    this.onConfigUpdate(false);
  }

  updateConfigByPartyType(): void {
    this.hideComponent = this.customerInformation?.get('partyType')?.value === 'S' ? false : true;
  }

  updateConfigBySplits(): void {
    const splits = this.salesCommissions?.get('brokerCommissionSplits')?.value;

    if (this.config.brokerCommissionSplits.visible && splits) {
      this.config.brokerCommissionListing = {
        visible: true,
        required: this.mainConfig.brokerCommissionListing.required
      };

      this.config.brokerCommissionSelling = {
        visible: true,
        required: this.mainConfig.brokerCommissionSelling.required
      };

      if (
        this.mainConfig.brokerCommissionTaxListing.visible &&
        this.mainConfig.brokerCommissionTaxSelling.visible &&
        this.mainConfig.brokerCommissionTaxTotal.visible
      ) {
        this.config.brokerCommissionTaxListing = {
          visible: true,
          required: this.mainConfig.brokerCommissionTaxListing.required
        };

        this.config.brokerCommissionTaxSelling = {
          visible: true,
          required: this.mainConfig.brokerCommissionTaxSelling.required
        };

        this.config.brokerCommissionTaxTotal = {
          visible: false,
          required: false
        };
      }
    } else {
      this.config.brokerCommissionListing = {
        visible: false,
        required: false
      };

      this.config.brokerCommissionSelling = {
        visible: false,
        required: false
      };

      if (
        this.mainConfig.brokerCommissionTaxListing.visible &&
        this.mainConfig.brokerCommissionTaxSelling.visible &&
        this.mainConfig.brokerCommissionTaxTotal.visible
      ) {
        this.config.brokerCommissionTaxListing = {
          visible: false,
          required: false
        };

        this.config.brokerCommissionTaxSelling = {
          visible: false,
          required: false
        };

        this.config.brokerCommissionTaxTotal = {
          visible: true,
          required: this.mainConfig.brokerCommissionTaxTotal.required
        };
      }
    }
  }

  onConfigUpdate(isMainConfigUpdated = false): void {
    for (const field of Object.keys(this.config)) {
      if (isMainConfigUpdated) {
        this.config[field] = this.mainConfig[field];
      }

      if (this.config[field].required) {
        this.salesCommissions?.get(field)?.setValidators([Validators.required]);
      } else {
        this.salesCommissions?.get(field)?.clearValidators();
      }
    }

    this.salesCommissions?.updateValueAndValidity({ emitEvent: false });
  }
}
