import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { FipsModel } from '../models/fips.model';
import { PrefillModel } from '../models/prefill.model';
import { NetsheetService } from './netsheet.service';

@Injectable({
  providedIn: 'root'
})
export class PrefillService {
  default: PrefillModel = {
    propertyAddress: '',
    propertyState: 'CA',
    propertyCounty: '',
    propertyCity: '',
    propertyZip: '',

    userCompany: '',
    userEmail: '',
    userFirstName: '',
    userLastName: '',
    userPhone: ''
  };

  prefill: BehaviorSubject<PrefillModel>;

  constructor(private netsheetService: NetsheetService) {
    this.prefill = new BehaviorSubject<PrefillModel>(this.getDefault());
  }

  getDefault(): PrefillModel {
    return Object.assign({}, this.default);
  }

  setDefaultPrefill(): void {
    this.prefill.next(this.getDefault());
  }

  getPrefill(): Observable<PrefillModel> {
    return this.prefill.asObservable();
  }

  updatePrefill(prefill: PrefillModel): void {
    prefill.propertyState =
      prefill.propertyState &&
      ['AZ', 'CA', 'HI', 'NM', 'NV', 'OH', 'OK', 'OR', 'TX', 'UT', 'WA'].includes(prefill.propertyState)
        ? prefill.propertyState
        : 'CA';

    prefill.userPhone = prefill.userPhone ? prefill.userPhone.replace(/[^0-9]/g, '').substring(0, 10) : '';
    prefill.propertyZip = prefill.propertyZip ? prefill.propertyZip.replace(/[^0-9]/g, '').substring(0, 5) : '';

    if (prefill.propertyFips) {
      const subscription = this.netsheetService
        .getStateAndCountyByFips(prefill.propertyFips)
        .subscribe((fips: FipsModel) => {
          if (fips && ['AZ', 'CA', 'HI', 'NM', 'NV', 'OH', 'OK', 'OR', 'TX', 'UT', 'WA'].includes(fips.state)) {
            prefill.propertyState = fips.state;
            prefill.propertyCounty = fips.county;
          }

          this.prefill.next({ ...this.prefill.value, ...prefill });

          subscription.unsubscribe();
        });
    } else {
      this.prefill.next({ ...this.prefill.value, ...prefill });
    }
  }
}
