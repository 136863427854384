import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireConfigService {
  config: any = {
    AZ: {
      attBrokerageFee: {
        required: false,
        visible: false
      },
      attEstcoe: {
        required: true,
        visible: true
      },
      attFirpta: {
        required: true,
        visible: true
      },
      attFtb: {
        required: false,
        visible: false
      },
      attHarpta: {
        required: false,
        visible: false
      },
      attNewLoan: {
        required: true,
        visible: true
      },
      attOwnersExemption: {
        required: false,
        visible: false
      },
      attOwnersPolicySplit: {
        required: false,
        visible: false
      },
      attPersonalResidence: {
        required: true,
        visible: true
      },
      attReo: {
        required: true,
        visible: true
      },
      attSalesPrice: {
        required: true,
        visible: true
      },
      attSewerBill: {
        required: false,
        visible: false
      },
      attShortSale: {
        required: true,
        visible: true
      },
      attSubEscrow: {
        required: false,
        visible: false
      },
      attSurveyAmendmentSplit: {
        required: false,
        visible: false
      },
      attWaterBill: {
        required: false,
        visible: false
      },
      brokerCommissionListing: {
        required: false,
        visible: true
      },
      brokerCommissionSelling: {
        required: false,
        visible: true
      },
      brokerCommissionSplits: {
        required: true,
        visible: true
      },
      brokerCommissionTaxListing: {
        required: false,
        visible: false
      },
      brokerCommissionTaxSelling: {
        required: false,
        visible: false
      },
      brokerCommissionTaxTotal: {
        required: false,
        visible: false
      },
      brokerCommissionTierRate: {
        required: false,
        visible: false
      },
      brokerCommissionTotal: {
        required: false,
        visible: true
      },
      buyerCreditAmount: {
        required: false,
        visible: true
      },
      escrowBranch: {
        required: false,
        visible: false
      },
      escrowCity: {
        required: false,
        visible: false
      },
      escrowCounty: {
        required: true,
        visible: true
      },
      escrowState: {
        required: true,
        visible: true
      },
      hazardInsuranceAmount: {
        required: false,
        visible: true
      },
      homeownersDuesAmount: {
        required: false,
        visible: true
      },
      homeownersDuesDueDate: {
        required: false,
        visible: true
      },
      homeownersDuesPaid: {
        required: false,
        visible: false
      },
      homeownersDuesTerm: {
        required: false,
        visible: true
      },
      loanPrimaryAmount: {
        required: true,
        visible: true
      },
      loanPrimaryInterestRate: {
        required: false,
        visible: true
      },
      loanPrimaryOriginationFees: {
        required: false,
        visible: true
      },
      mortgagePayoffCount: {
        required: false,
        visible: false
      },
      mortgagePrimaryBalance: {
        required: false,
        visible: true
      },
      mortgagePrimaryInterestRate: {
        required: false,
        visible: true
      },
      mortgagePrimaryLienCloseDate: {
        required: false,
        visible: false
      },
      mortgagePrimaryPrepayPenalty: {
        required: false,
        visible: true
      },
      mortgageSecondaryBalance: {
        required: false,
        visible: true
      },
      mortgageSecondaryInterestRate: {
        required: false,
        visible: true
      },
      mortgageSecondaryPrepayPenalty: {
        required: false,
        visible: true
      },
      partyFirstName: {
        required: true,
        visible: true
      },
      partyLastName: {
        required: true,
        visible: true
      },
      partyType: {
        required: true,
        visible: true
      },
      propertyAddress: {
        required: false,
        visible: true
      },
      propertyCity: {
        required: true,
        visible: true
      },
      propertyCounty: {
        required: true,
        visible: true
      },
      propertyIncorporatedArea: {
        required: false,
        visible: false
      },
      propertyState: {
        required: true,
        visible: true
      },
      propertyZip: {
        required: false,
        visible: true
      },
      sellerResidentOfHawaii: {
        required: false,
        visible: false
      },
      taxes: {
        required: false,
        visible: true
      },
      taxesNextInstallment: {
        required: false,
        visible: true
      },
      taxesPaid: {
        required: false,
        visible: false
      },
      taxesPaidToDatePicker: {
        required: false,
        visible: true
      },
      taxesPaidToDateDropdown: {
        required: false,
        visible: false
      },
      taxesTerm: {
        required: false,
        visible: true
      },
      userCompany: {
        required: false,
        visible: true
      },
      userEmail: {
        required: false,
        visible: true
      },
      userFirstName: {
        required: false,
        visible: true
      },
      userLastName: {
        required: false,
        visible: true
      },
      userPhone: {
        required: false,
        visible: true
      },
      warrantyPremiumAmount: {
        required: false,
        visible: true
      }
    },
    CA: {
      attBrokerageFee: {
        required: false,
        visible: false
      },
      attEstcoe: {
        required: true,
        visible: true
      },
      attFirpta: {
        required: true,
        visible: true
      },
      attFtb: {
        required: true,
        visible: true
      },
      attHarpta: {
        required: false,
        visible: false
      },
      attNewLoan: {
        required: true,
        visible: true
      },
      attOwnersExemption: {
        required: false,
        visible: false
      },
      attOwnersPolicySplit: {
        required: false,
        visible: false
      },
      attPersonalResidence: {
        required: true,
        visible: true
      },
      attReo: {
        required: true,
        visible: true
      },
      attSalesPrice: {
        required: true,
        visible: true
      },
      attSewerBill: {
        required: false,
        visible: false
      },
      attShortSale: {
        required: true,
        visible: true
      },
      attSubEscrow: {
        required: true,
        visible: true
      },
      attSurveyAmendmentSplit: {
        required: false,
        visible: false
      },
      attWaterBill: {
        required: false,
        visible: false
      },
      brokerCommissionListing: {
        required: false,
        visible: true
      },
      brokerCommissionSelling: {
        required: false,
        visible: true
      },
      brokerCommissionSplits: {
        required: true,
        visible: true
      },
      brokerCommissionTaxListing: {
        required: false,
        visible: false
      },
      brokerCommissionTaxSelling: {
        required: false,
        visible: false
      },
      brokerCommissionTaxTotal: {
        required: false,
        visible: false
      },
      brokerCommissionTierRate: {
        required: false,
        visible: false
      },
      brokerCommissionTotal: {
        required: false,
        visible: true
      },
      buyerCreditAmount: {
        required: false,
        visible: true
      },
      escrowBranch: {
        required: false,
        visible: false
      },
      escrowCity: {
        required: false,
        visible: false
      },
      escrowCounty: {
        required: true,
        visible: true
      },
      escrowState: {
        required: true,
        visible: true
      },
      hazardInsuranceAmount: {
        required: false,
        visible: true
      },
      homeownersDuesAmount: {
        required: false,
        visible: true
      },
      homeownersDuesDueDate: {
        required: false,
        visible: true
      },
      homeownersDuesPaid: {
        required: false,
        visible: false
      },
      homeownersDuesTerm: {
        required: false,
        visible: true
      },
      loanPrimaryAmount: {
        required: true,
        visible: true
      },
      loanPrimaryInterestRate: {
        required: false,
        visible: true
      },
      loanPrimaryOriginationFees: {
        required: false,
        visible: true
      },
      mortgagePayoffCount: {
        required: false,
        visible: false
      },
      mortgagePrimaryBalance: {
        required: false,
        visible: true
      },
      mortgagePrimaryInterestRate: {
        required: false,
        visible: true
      },
      mortgagePrimaryLienCloseDate: {
        required: false,
        visible: false
      },
      mortgagePrimaryPrepayPenalty: {
        required: false,
        visible: true
      },
      mortgageSecondaryBalance: {
        required: false,
        visible: true
      },
      mortgageSecondaryInterestRate: {
        required: false,
        visible: true
      },
      mortgageSecondaryPrepayPenalty: {
        required: false,
        visible: true
      },
      partyFirstName: {
        required: true,
        visible: true
      },
      partyLastName: {
        required: true,
        visible: true
      },
      partyType: {
        required: true,
        visible: true
      },
      propertyAddress: {
        required: false,
        visible: true
      },
      propertyCity: {
        required: true,
        visible: true
      },
      propertyCounty: {
        required: true,
        visible: true
      },
      propertyIncorporatedArea: {
        required: true,
        visible: true
      },
      propertyState: {
        required: true,
        visible: true
      },
      propertyZip: {
        required: false,
        visible: true
      },
      sellerResidentOfHawaii: {
        required: false,
        visible: false
      },
      taxes: {
        required: false,
        visible: true
      },
      taxesNextInstallment: {
        required: false,
        visible: false
      },
      taxesPaid: {
        required: false,
        visible: false
      },
      taxesPaidToDatePicker: {
        required: false,
        visible: false
      },
      taxesPaidToDateDropdown: {
        required: false,
        visible: true
      },
      taxesTerm: {
        required: false,
        visible: false
      },
      userCompany: {
        required: false,
        visible: true
      },
      userEmail: {
        required: false,
        visible: true
      },
      userFirstName: {
        required: false,
        visible: true
      },
      userLastName: {
        required: false,
        visible: true
      },
      userPhone: {
        required: false,
        visible: true
      },
      warrantyPremiumAmount: {
        required: false,
        visible: true
      }
    },
    HI: {
      attBrokerageFee: {
        required: false,
        visible: false
      },
      attEstcoe: {
        required: true,
        visible: true
      },
      attFirpta: {
        required: true,
        visible: true
      },
      attFtb: {
        required: false,
        visible: false
      },
      attHarpta: {
        required: true,
        visible: true
      },
      attNewLoan: {
        required: true,
        visible: true
      },
      attOwnersExemption: {
        required: true,
        visible: true
      },
      attOwnersPolicySplit: {
        required: false,
        visible: false
      },
      attPersonalResidence: {
        required: true,
        visible: true
      },
      attReo: {
        required: true,
        visible: true
      },
      attSalesPrice: {
        required: true,
        visible: true
      },
      attSewerBill: {
        required: false,
        visible: false
      },
      attShortSale: {
        required: true,
        visible: true
      },
      attSubEscrow: {
        required: false,
        visible: false
      },
      attSurveyAmendmentSplit: {
        required: false,
        visible: false
      },
      attWaterBill: {
        required: false,
        visible: false
      },
      brokerCommissionListing: {
        required: false,
        visible: true
      },
      brokerCommissionSelling: {
        required: false,
        visible: true
      },
      brokerCommissionSplits: {
        required: true,
        visible: true
      },
      brokerCommissionTaxListing: {
        required: false,
        visible: true
      },
      brokerCommissionTaxSelling: {
        required: false,
        visible: true
      },
      brokerCommissionTaxTotal: {
        required: false,
        visible: true
      },
      brokerCommissionTierRate: {
        required: false,
        visible: false
      },
      brokerCommissionTotal: {
        required: false,
        visible: true
      },
      buyerCreditAmount: {
        required: false,
        visible: true
      },
      escrowBranch: {
        required: false,
        visible: false
      },
      escrowCity: {
        required: false,
        visible: false
      },
      escrowCounty: {
        required: true,
        visible: true
      },
      escrowState: {
        required: true,
        visible: true
      },
      hazardInsuranceAmount: {
        required: false,
        visible: true
      },
      homeownersDuesAmount: {
        required: false,
        visible: true
      },
      homeownersDuesDueDate: {
        required: false,
        visible: true
      },
      homeownersDuesPaid: {
        required: false,
        visible: false
      },
      homeownersDuesTerm: {
        required: false,
        visible: true
      },
      loanPrimaryAmount: {
        required: true,
        visible: true
      },
      loanPrimaryInterestRate: {
        required: false,
        visible: false
      },
      loanPrimaryOriginationFees: {
        required: false,
        visible: false
      },
      mortgagePayoffCount: {
        required: false,
        visible: false
      },
      mortgagePrimaryBalance: {
        required: false,
        visible: true
      },
      mortgagePrimaryInterestRate: {
        required: false,
        visible: true
      },
      mortgagePrimaryLienCloseDate: {
        required: false,
        visible: false
      },
      mortgagePrimaryPrepayPenalty: {
        required: false,
        visible: true
      },
      mortgageSecondaryBalance: {
        required: false,
        visible: true
      },
      mortgageSecondaryInterestRate: {
        required: false,
        visible: true
      },
      mortgageSecondaryPrepayPenalty: {
        required: false,
        visible: true
      },
      partyFirstName: {
        required: true,
        visible: true
      },
      partyLastName: {
        required: true,
        visible: true
      },
      partyType: {
        required: true,
        visible: true
      },
      propertyAddress: {
        required: false,
        visible: true
      },
      propertyCity: {
        required: true,
        visible: true
      },
      propertyCounty: {
        required: true,
        visible: true
      },
      propertyIncorporatedArea: {
        required: false,
        visible: false
      },
      propertyState: {
        required: true,
        visible: true
      },
      propertyZip: {
        required: false,
        visible: true
      },
      sellerResidentOfHawaii: {
        required: true,
        visible: true
      },
      taxes: {
        required: false,
        visible: true
      },
      taxesNextInstallment: {
        required: false,
        visible: true
      },
      taxesPaid: {
        required: false,
        visible: false
      },
      taxesPaidToDatePicker: {
        required: false,
        visible: true
      },
      taxesPaidToDateDropdown: {
        required: false,
        visible: false
      },
      taxesTerm: {
        required: false,
        visible: true
      },
      userCompany: {
        required: false,
        visible: true
      },
      userEmail: {
        required: false,
        visible: true
      },
      userFirstName: {
        required: false,
        visible: true
      },
      userLastName: {
        required: false,
        visible: true
      },
      userPhone: {
        required: false,
        visible: true
      },
      warrantyPremiumAmount: {
        required: false,
        visible: true
      }
    },
    NM: {
      attBrokerageFee: {
        required: false,
        visible: false
      },
      attEstcoe: {
        required: true,
        visible: true
      },
      attFirpta: {
        required: true,
        visible: true
      },
      attFtb: {
        required: false,
        visible: false
      },
      attHarpta: {
        required: false,
        visible: false
      },
      attNewLoan: {
        required: true,
        visible: true
      },
      attOwnersExemption: {
        required: false,
        visible: false
      },
      attOwnersPolicySplit: {
        required: false,
        visible: false
      },
      attPersonalResidence: {
        required: true,
        visible: true
      },
      attReo: {
        required: false,
        visible: false
      },
      attSalesPrice: {
        required: true,
        visible: true
      },
      attSewerBill: {
        required: false,
        visible: false
      },
      attShortSale: {
        required: false,
        visible: false
      },
      attSubEscrow: {
        required: false,
        visible: false
      },
      attSurveyAmendmentSplit: {
        required: false,
        visible: false
      },
      attWaterBill: {
        required: false,
        visible: false
      },
      brokerCommissionListing: {
        required: false,
        visible: true
      },
      brokerCommissionSelling: {
        required: false,
        visible: true
      },
      brokerCommissionSplits: {
        required: true,
        visible: true
      },
      brokerCommissionTaxListing: {
        required: false,
        visible: false
      },
      brokerCommissionTaxSelling: {
        required: false,
        visible: false
      },
      brokerCommissionTaxTotal: {
        required: false,
        visible: false
      },
      brokerCommissionTierRate: {
        required: false,
        visible: false
      },
      brokerCommissionTotal: {
        required: false,
        visible: true
      },
      buyerCreditAmount: {
        required: false,
        visible: true
      },
      escrowBranch: {
        required: false,
        visible: false
      },
      escrowCity: {
        required: false,
        visible: false
      },
      escrowCounty: {
        required: true,
        visible: true
      },
      escrowState: {
        required: true,
        visible: true
      },
      hazardInsuranceAmount: {
        required: false,
        visible: true
      },
      homeownersDuesAmount: {
        required: false,
        visible: true
      },
      homeownersDuesDueDate: {
        required: false,
        visible: true
      },
      homeownersDuesPaid: {
        required: false,
        visible: false
      },
      homeownersDuesTerm: {
        required: false,
        visible: true
      },
      loanPrimaryAmount: {
        required: true,
        visible: true
      },
      loanPrimaryInterestRate: {
        required: false,
        visible: true
      },
      loanPrimaryOriginationFees: {
        required: false,
        visible: true
      },
      mortgagePayoffCount: {
        required: false,
        visible: false
      },
      mortgagePrimaryBalance: {
        required: false,
        visible: true
      },
      mortgagePrimaryInterestRate: {
        required: false,
        visible: true
      },
      mortgagePrimaryLienCloseDate: {
        required: false,
        visible: false
      },
      mortgagePrimaryPrepayPenalty: {
        required: false,
        visible: true
      },
      mortgageSecondaryBalance: {
        required: false,
        visible: true
      },
      mortgageSecondaryInterestRate: {
        required: false,
        visible: true
      },
      mortgageSecondaryPrepayPenalty: {
        required: false,
        visible: true
      },
      partyFirstName: {
        required: true,
        visible: true
      },
      partyLastName: {
        required: true,
        visible: true
      },
      partyType: {
        required: true,
        visible: true
      },
      propertyAddress: {
        required: false,
        visible: true
      },
      propertyCity: {
        required: true,
        visible: true
      },
      propertyCounty: {
        required: true,
        visible: true
      },
      propertyIncorporatedArea: {
        required: false,
        visible: false
      },
      propertyState: {
        required: true,
        visible: true
      },
      propertyZip: {
        required: false,
        visible: true
      },
      sellerResidentOfHawaii: {
        required: false,
        visible: false
      },
      taxes: {
        required: false,
        visible: true
      },
      taxesNextInstallment: {
        required: false,
        visible: false
      },
      taxesPaid: {
        required: false,
        visible: true
      },
      taxesPaidToDatePicker: {
        required: false,
        visible: false
      },
      taxesPaidToDateDropdown: {
        required: false,
        visible: false
      },
      taxesTerm: {
        required: false,
        visible: false
      },
      userCompany: {
        required: false,
        visible: true
      },
      userEmail: {
        required: false,
        visible: true
      },
      userFirstName: {
        required: false,
        visible: true
      },
      userLastName: {
        required: false,
        visible: true
      },
      userPhone: {
        required: false,
        visible: true
      },
      warrantyPremiumAmount: {
        required: false,
        visible: false
      }
    },
    NV: {
      attBrokerageFee: {
        required: false,
        visible: false
      },
      attEstcoe: {
        required: true,
        visible: true
      },
      attFirpta: {
        required: true,
        visible: true
      },
      attFtb: {
        required: false,
        visible: false
      },
      attHarpta: {
        required: false,
        visible: false
      },
      attNewLoan: {
        required: true,
        visible: true
      },
      attOwnersExemption: {
        required: false,
        visible: false
      },
      attOwnersPolicySplit: {
        required: false,
        visible: false
      },
      attPersonalResidence: {
        required: true,
        visible: true
      },
      attReo: {
        required: true,
        visible: true
      },
      attSalesPrice: {
        required: true,
        visible: true
      },
      attSewerBill: {
        required: false,
        visible: false
      },
      attShortSale: {
        required: true,
        visible: true
      },
      attSubEscrow: {
        required: false,
        visible: false
      },
      attSurveyAmendmentSplit: {
        required: false,
        visible: false
      },
      attWaterBill: {
        required: false,
        visible: false
      },
      brokerCommissionListing: {
        required: false,
        visible: true
      },
      brokerCommissionSelling: {
        required: false,
        visible: true
      },
      brokerCommissionSplits: {
        required: true,
        visible: true
      },
      brokerCommissionTaxListing: {
        required: false,
        visible: false
      },
      brokerCommissionTaxSelling: {
        required: false,
        visible: false
      },
      brokerCommissionTaxTotal: {
        required: false,
        visible: false
      },
      brokerCommissionTierRate: {
        required: false,
        visible: false
      },
      brokerCommissionTotal: {
        required: false,
        visible: true
      },
      buyerCreditAmount: {
        required: false,
        visible: true
      },
      escrowBranch: {
        required: false,
        visible: false
      },
      escrowCity: {
        required: false,
        visible: false
      },
      escrowCounty: {
        required: true,
        visible: true
      },
      escrowState: {
        required: true,
        visible: true
      },
      hazardInsuranceAmount: {
        required: false,
        visible: true
      },
      homeownersDuesAmount: {
        required: false,
        visible: true
      },
      homeownersDuesDueDate: {
        required: false,
        visible: true
      },
      homeownersDuesPaid: {
        required: false,
        visible: false
      },
      homeownersDuesTerm: {
        required: false,
        visible: true
      },
      loanPrimaryAmount: {
        required: true,
        visible: true
      },
      loanPrimaryInterestRate: {
        required: false,
        visible: true
      },
      loanPrimaryOriginationFees: {
        required: false,
        visible: true
      },
      mortgagePayoffCount: {
        required: false,
        visible: false
      },
      mortgagePrimaryBalance: {
        required: false,
        visible: true
      },
      mortgagePrimaryInterestRate: {
        required: false,
        visible: true
      },
      mortgagePrimaryLienCloseDate: {
        required: false,
        visible: false
      },
      mortgagePrimaryPrepayPenalty: {
        required: false,
        visible: true
      },
      mortgageSecondaryBalance: {
        required: false,
        visible: true
      },
      mortgageSecondaryInterestRate: {
        required: false,
        visible: true
      },
      mortgageSecondaryPrepayPenalty: {
        required: false,
        visible: true
      },
      partyFirstName: {
        required: true,
        visible: true
      },
      partyLastName: {
        required: true,
        visible: true
      },
      partyType: {
        required: true,
        visible: true
      },
      propertyAddress: {
        required: false,
        visible: true
      },
      propertyCity: {
        required: true,
        visible: true
      },
      propertyCounty: {
        required: true,
        visible: true
      },
      propertyIncorporatedArea: {
        required: false,
        visible: false
      },
      propertyState: {
        required: true,
        visible: true
      },
      propertyZip: {
        required: false,
        visible: true
      },
      sellerResidentOfHawaii: {
        required: false,
        visible: false
      },
      taxes: {
        required: false,
        visible: true
      },
      taxesNextInstallment: {
        required: false,
        visible: true
      },
      taxesPaid: {
        required: false,
        visible: false
      },
      taxesPaidToDatePicker: {
        required: false,
        visible: true
      },
      taxesPaidToDateDropdown: {
        required: false,
        visible: false
      },
      taxesTerm: {
        required: false,
        visible: true
      },
      userCompany: {
        required: false,
        visible: true
      },
      userEmail: {
        required: false,
        visible: true
      },
      userFirstName: {
        required: false,
        visible: true
      },
      userLastName: {
        required: false,
        visible: true
      },
      userPhone: {
        required: false,
        visible: true
      },
      warrantyPremiumAmount: {
        required: false,
        visible: true
      }
    },
    OH: {
      attBrokerageFee: {
        required: false,
        visible: true
      },
      attEstcoe: {
        required: true,
        visible: true
      },
      attFirpta: {
        required: true,
        visible: true
      },
      attFtb: {
        required: false,
        visible: false
      },
      attHarpta: {
        required: false,
        visible: false
      },
      attNewLoan: {
        required: true,
        visible: true
      },
      attOwnersExemption: {
        required: false,
        visible: false
      },
      attOwnersPolicySplit: {
        required: false,
        visible: false
      },
      attPersonalResidence: {
        required: true,
        visible: true
      },
      attReo: {
        required: false,
        visible: false
      },
      attSalesPrice: {
        required: true,
        visible: true
      },
      attSewerBill: {
        required: false,
        visible: true
      },
      attShortSale: {
        required: false,
        visible: false
      },
      attSubEscrow: {
        required: false,
        visible: false
      },
      attSurveyAmendmentSplit: {
        required: false,
        visible: false
      },
      attWaterBill: {
        required: false,
        visible: true
      },
      brokerCommissionListing: {
        required: false,
        visible: true
      },
      brokerCommissionSelling: {
        required: false,
        visible: true
      },
      brokerCommissionSplits: {
        required: true,
        visible: true
      },
      brokerCommissionTaxListing: {
        required: false,
        visible: false
      },
      brokerCommissionTaxSelling: {
        required: false,
        visible: false
      },
      brokerCommissionTaxTotal: {
        required: false,
        visible: false
      },
      brokerCommissionTierRate: {
        required: false,
        visible: true
      },
      brokerCommissionTotal: {
        required: false,
        visible: true
      },
      buyerCreditAmount: {
        required: false,
        visible: true
      },
      escrowBranch: {
        required: false,
        visible: false
      },
      escrowCity: {
        required: false,
        visible: false
      },
      escrowCounty: {
        required: true,
        visible: true
      },
      escrowState: {
        required: true,
        visible: true
      },
      hazardInsuranceAmount: {
        required: false,
        visible: true
      },
      homeownersDuesAmount: {
        required: false,
        visible: true
      },
      homeownersDuesDueDate: {
        required: false,
        visible: true
      },
      homeownersDuesPaid: {
        required: false,
        visible: false
      },
      homeownersDuesTerm: {
        required: false,
        visible: true
      },
      loanPrimaryAmount: {
        required: true,
        visible: true
      },
      loanPrimaryInterestRate: {
        required: false,
        visible: false
      },
      loanPrimaryOriginationFees: {
        required: false,
        visible: false
      },
      mortgagePayoffCount: {
        required: false,
        visible: false
      },
      mortgagePrimaryBalance: {
        required: false,
        visible: true
      },
      mortgagePrimaryInterestRate: {
        required: false,
        visible: false
      },
      mortgagePrimaryLienCloseDate: {
        required: false,
        visible: false
      },
      mortgagePrimaryPrepayPenalty: {
        required: false,
        visible: true
      },
      mortgageSecondaryBalance: {
        required: false,
        visible: true
      },
      mortgageSecondaryInterestRate: {
        required: false,
        visible: false
      },
      mortgageSecondaryPrepayPenalty: {
        required: false,
        visible: true
      },
      partyFirstName: {
        required: true,
        visible: true
      },
      partyLastName: {
        required: true,
        visible: true
      },
      partyType: {
        required: true,
        visible: true
      },
      propertyAddress: {
        required: false,
        visible: true
      },
      propertyCity: {
        required: false,
        visible: false
      },
      propertyCounty: {
        required: true,
        visible: true
      },
      propertyIncorporatedArea: {
        required: false,
        visible: false
      },
      propertyState: {
        required: true,
        visible: true
      },
      propertyZip: {
        required: false,
        visible: false
      },
      sellerResidentOfHawaii: {
        required: false,
        visible: false
      },
      taxes: {
        required: false,
        visible: true
      },
      taxesNextInstallment: {
        required: false,
        visible: false
      },
      taxesPaid: {
        required: false,
        visible: false
      },
      taxesPaidToDatePicker: {
        required: false,
        visible: false
      },
      taxesPaidToDateDropdown: {
        required: false,
        visible: true
      },
      taxesTerm: {
        required: false,
        visible: false
      },
      userCompany: {
        required: false,
        visible: true
      },
      userEmail: {
        required: false,
        visible: true
      },
      userFirstName: {
        required: false,
        visible: true
      },
      userLastName: {
        required: false,
        visible: true
      },
      userPhone: {
        required: false,
        visible: true
      },
      warrantyPremiumAmount: {
        required: false,
        visible: true
      }
    },
    OK: {
      attBrokerageFee: {
        required: false,
        visible: false
      },
      attEstcoe: {
        required: true,
        visible: true
      },
      attFirpta: {
        required: true,
        visible: true
      },
      attFtb: {
        required: false,
        visible: false
      },
      attHarpta: {
        required: false,
        visible: false
      },
      attNewLoan: {
        required: true,
        visible: true
      },
      attOwnersExemption: {
        required: false,
        visible: false
      },
      attOwnersPolicySplit: {
        required: false,
        visible: false
      },
      attPersonalResidence: {
        required: true,
        visible: true
      },
      attReo: {
        required: false,
        visible: false
      },
      attSalesPrice: {
        required: true,
        visible: true
      },
      attSewerBill: {
        required: false,
        visible: false
      },
      attShortSale: {
        required: false,
        visible: false
      },
      attSubEscrow: {
        required: false,
        visible: false
      },
      attSurveyAmendmentSplit: {
        required: false,
        visible: false
      },
      attWaterBill: {
        required: false,
        visible: false
      },
      brokerCommissionListing: {
        required: false,
        visible: true
      },
      brokerCommissionSelling: {
        required: false,
        visible: true
      },
      brokerCommissionSplits: {
        required: true,
        visible: true
      },
      brokerCommissionTaxListing: {
        required: false,
        visible: false
      },
      brokerCommissionTaxSelling: {
        required: false,
        visible: false
      },
      brokerCommissionTaxTotal: {
        required: false,
        visible: false
      },
      brokerCommissionTierRate: {
        required: false,
        visible: false
      },
      brokerCommissionTotal: {
        required: false,
        visible: true
      },
      buyerCreditAmount: {
        required: false,
        visible: true
      },
      escrowBranch: {
        required: false,
        visible: false
      },
      escrowCity: {
        required: false,
        visible: false
      },
      escrowCounty: {
        required: false,
        visible: false
      },
      escrowState: {
        required: true,
        visible: true
      },
      hazardInsuranceAmount: {
        required: false,
        visible: true
      },
      homeownersDuesAmount: {
        required: false,
        visible: true
      },
      homeownersDuesDueDate: {
        required: false,
        visible: true
      },
      homeownersDuesPaid: {
        required: false,
        visible: false
      },
      homeownersDuesTerm: {
        required: false,
        visible: true
      },
      loanPrimaryAmount: {
        required: true,
        visible: true
      },
      loanPrimaryInterestRate: {
        required: false,
        visible: true
      },
      loanPrimaryOriginationFees: {
        required: false,
        visible: true
      },
      mortgagePayoffCount: {
        required: false,
        visible: false
      },
      mortgagePrimaryBalance: {
        required: false,
        visible: true
      },
      mortgagePrimaryInterestRate: {
        required: false,
        visible: true
      },
      mortgagePrimaryLienCloseDate: {
        required: false,
        visible: false
      },
      mortgagePrimaryPrepayPenalty: {
        required: false,
        visible: true
      },
      mortgageSecondaryBalance: {
        required: false,
        visible: true
      },
      mortgageSecondaryInterestRate: {
        required: false,
        visible: true
      },
      mortgageSecondaryPrepayPenalty: {
        required: false,
        visible: true
      },
      partyFirstName: {
        required: true,
        visible: true
      },
      partyLastName: {
        required: true,
        visible: true
      },
      partyType: {
        required: true,
        visible: true
      },
      propertyAddress: {
        required: false,
        visible: true
      },
      propertyCity: {
        required: true,
        visible: true
      },
      propertyCounty: {
        required: true,
        visible: true
      },
      propertyIncorporatedArea: {
        required: false,
        visible: false
      },
      propertyState: {
        required: true,
        visible: true
      },
      propertyZip: {
        required: false,
        visible: true
      },
      sellerResidentOfHawaii: {
        required: false,
        visible: false
      },
      taxes: {
        required: false,
        visible: true
      },
      taxesNextInstallment: {
        required: false,
        visible: false
      },
      taxesPaid: {
        required: true,
        visible: true
      },
      taxesPaidToDatePicker: {
        required: false,
        visible: false
      },
      taxesPaidToDateDropdown: {
        required: false,
        visible: false
      },
      taxesTerm: {
        required: false,
        visible: false
      },
      userCompany: {
        required: false,
        visible: true
      },
      userEmail: {
        required: false,
        visible: true
      },
      userFirstName: {
        required: false,
        visible: true
      },
      userLastName: {
        required: false,
        visible: true
      },
      userPhone: {
        required: false,
        visible: true
      },
      warrantyPremiumAmount: {
        required: false,
        visible: true
      }
    },
    OR: {
      attBrokerageFee: {
        required: false,
        visible: false
      },
      attEstcoe: {
        required: true,
        visible: true
      },
      attFirpta: {
        required: true,
        visible: true
      },
      attFtb: {
        required: false,
        visible: false
      },
      attHarpta: {
        required: false,
        visible: false
      },
      attNewLoan: {
        required: true,
        visible: true
      },
      attOwnersExemption: {
        required: false,
        visible: false
      },
      attOwnersPolicySplit: {
        required: false,
        visible: false
      },
      attPersonalResidence: {
        required: true,
        visible: true
      },
      attReo: {
        required: false,
        visible: false
      },
      attSalesPrice: {
        required: true,
        visible: true
      },
      attSewerBill: {
        required: false,
        visible: false
      },
      attShortSale: {
        required: false,
        visible: false
      },
      attSubEscrow: {
        required: false,
        visible: false
      },
      attSurveyAmendmentSplit: {
        required: false,
        visible: false
      },
      attWaterBill: {
        required: false,
        visible: false
      },
      brokerCommissionListing: {
        required: false,
        visible: true
      },
      brokerCommissionSelling: {
        required: false,
        visible: true
      },
      brokerCommissionSplits: {
        required: true,
        visible: true
      },
      brokerCommissionTaxListing: {
        required: false,
        visible: false
      },
      brokerCommissionTaxSelling: {
        required: false,
        visible: false
      },
      brokerCommissionTaxTotal: {
        required: false,
        visible: false
      },
      brokerCommissionTierRate: {
        required: false,
        visible: false
      },
      brokerCommissionTotal: {
        required: false,
        visible: true
      },
      buyerCreditAmount: {
        required: false,
        visible: true
      },
      escrowBranch: {
        required: false,
        visible: false
      },
      escrowCity: {
        required: false,
        visible: false
      },
      escrowCounty: {
        required: false,
        visible: false
      },
      escrowState: {
        required: false,
        visible: false
      },
      hazardInsuranceAmount: {
        required: false,
        visible: true
      },
      homeownersDuesAmount: {
        required: false,
        visible: true
      },
      homeownersDuesDueDate: {
        required: false,
        visible: true
      },
      homeownersDuesPaid: {
        required: false,
        visible: false
      },
      homeownersDuesTerm: {
        required: false,
        visible: true
      },
      loanPrimaryAmount: {
        required: true,
        visible: true
      },
      loanPrimaryInterestRate: {
        required: false,
        visible: false
      },
      loanPrimaryOriginationFees: {
        required: false,
        visible: true
      },
      mortgagePayoffCount: {
        required: false,
        visible: true
      },
      mortgagePrimaryBalance: {
        required: false,
        visible: true
      },
      mortgagePrimaryInterestRate: {
        required: false,
        visible: false
      },
      mortgagePrimaryLienCloseDate: {
        required: false,
        visible: false
      },
      mortgagePrimaryPrepayPenalty: {
        required: false,
        visible: false
      },
      mortgageSecondaryBalance: {
        required: false,
        visible: true
      },
      mortgageSecondaryInterestRate: {
        required: false,
        visible: false
      },
      mortgageSecondaryPrepayPenalty: {
        required: false,
        visible: false
      },
      partyFirstName: {
        required: true,
        visible: true
      },
      partyLastName: {
        required: true,
        visible: true
      },
      partyType: {
        required: true,
        visible: true
      },
      propertyAddress: {
        required: false,
        visible: true
      },
      propertyCity: {
        required: true,
        visible: true
      },
      propertyCounty: {
        required: true,
        visible: true
      },
      propertyIncorporatedArea: {
        required: false,
        visible: false
      },
      propertyState: {
        required: true,
        visible: true
      },
      propertyZip: {
        required: false,
        visible: true
      },
      sellerResidentOfHawaii: {
        required: false,
        visible: false
      },
      taxes: {
        required: false,
        visible: true
      },
      taxesNextInstallment: {
        required: false,
        visible: false
      },
      taxesPaid: {
        required: true,
        visible: true
      },
      taxesPaidToDatePicker: {
        required: false,
        visible: false
      },
      taxesPaidToDateDropdown: {
        required: false,
        visible: false
      },
      taxesTerm: {
        required: false,
        visible: false
      },
      userCompany: {
        required: false,
        visible: true
      },
      userEmail: {
        required: false,
        visible: true
      },
      userFirstName: {
        required: false,
        visible: true
      },
      userLastName: {
        required: false,
        visible: true
      },
      userPhone: {
        required: false,
        visible: true
      },
      warrantyPremiumAmount: {
        required: false,
        visible: true
      }
    },
    TX: {
      attBrokerageFee: {
        required: false,
        visible: false
      },
      attEstcoe: {
        required: true,
        visible: true
      },
      attFirpta: {
        required: true,
        visible: true
      },
      attFtb: {
        required: false,
        visible: false
      },
      attHarpta: {
        required: false,
        visible: false
      },
      attNewLoan: {
        required: true,
        visible: true
      },
      attOwnersExemption: {
        required: false,
        visible: false
      },
      attOwnersPolicySplit: {
        required: true,
        visible: true
      },
      attPersonalResidence: {
        required: true,
        visible: true
      },
      attReo: {
        required: false,
        visible: false
      },
      attSalesPrice: {
        required: true,
        visible: true
      },
      attSewerBill: {
        required: false,
        visible: false
      },
      attShortSale: {
        required: false,
        visible: false
      },
      attSubEscrow: {
        required: true,
        visible: true
      },
      attSurveyAmendmentSplit: {
        required: true,
        visible: true
      },
      attWaterBill: {
        required: false,
        visible: false
      },
      brokerCommissionListing: {
        required: false,
        visible: true
      },
      brokerCommissionSelling: {
        required: false,
        visible: true
      },
      brokerCommissionSplits: {
        required: true,
        visible: true
      },
      brokerCommissionTaxListing: {
        required: false,
        visible: false
      },
      brokerCommissionTaxSelling: {
        required: false,
        visible: false
      },
      brokerCommissionTaxTotal: {
        required: false,
        visible: false
      },
      brokerCommissionTierRate: {
        required: false,
        visible: false
      },
      brokerCommissionTotal: {
        required: false,
        visible: true
      },
      buyerCreditAmount: {
        required: false,
        visible: true
      },
      escrowBranch: {
        required: true,
        visible: true
      },
      escrowCity: {
        required: false,
        visible: false
      },
      escrowCounty: {
        required: true,
        visible: true
      },
      escrowState: {
        required: true,
        visible: true
      },
      hazardInsuranceAmount: {
        required: false,
        visible: true
      },
      homeownersDuesAmount: {
        required: false,
        visible: true
      },
      homeownersDuesDueDate: {
        required: false,
        visible: false
      },
      homeownersDuesPaid: {
        required: false,
        visible: true
      },
      homeownersDuesTerm: {
        required: false,
        visible: false
      },
      loanPrimaryAmount: {
        required: true,
        visible: true
      },
      loanPrimaryInterestRate: {
        required: false,
        visible: true
      },
      loanPrimaryOriginationFees: {
        required: false,
        visible: true
      },
      mortgagePayoffCount: {
        required: false,
        visible: false
      },
      mortgagePrimaryBalance: {
        required: false,
        visible: true
      },
      mortgagePrimaryInterestRate: {
        required: false,
        visible: true
      },
      mortgagePrimaryLienCloseDate: {
        required: false,
        visible: true
      },
      mortgagePrimaryPrepayPenalty: {
        required: false,
        visible: true
      },
      mortgageSecondaryBalance: {
        required: false,
        visible: true
      },
      mortgageSecondaryInterestRate: {
        required: false,
        visible: true
      },
      mortgageSecondaryPrepayPenalty: {
        required: false,
        visible: true
      },
      partyFirstName: {
        required: true,
        visible: true
      },
      partyLastName: {
        required: true,
        visible: true
      },
      partyType: {
        required: true,
        visible: true
      },
      propertyAddress: {
        required: false,
        visible: true
      },
      propertyCity: {
        required: true,
        visible: true
      },
      propertyCounty: {
        required: true,
        visible: true
      },
      propertyIncorporatedArea: {
        required: false,
        visible: false
      },
      propertyState: {
        required: true,
        visible: true
      },
      propertyZip: {
        required: false,
        visible: true
      },
      sellerResidentOfHawaii: {
        required: false,
        visible: false
      },
      taxes: {
        required: false,
        visible: true
      },
      taxesNextInstallment: {
        required: false,
        visible: false
      },
      taxesPaid: {
        required: true,
        visible: true
      },
      taxesPaidToDatePicker: {
        required: false,
        visible: false
      },
      taxesPaidToDateDropdown: {
        required: false,
        visible: false
      },
      taxesTerm: {
        required: false,
        visible: false
      },
      userCompany: {
        required: false,
        visible: true
      },
      userEmail: {
        required: false,
        visible: true
      },
      userFirstName: {
        required: false,
        visible: true
      },
      userLastName: {
        required: false,
        visible: true
      },
      userPhone: {
        required: false,
        visible: true
      },
      warrantyPremiumAmount: {
        required: false,
        visible: true
      }
    },
    UT: {
      attBrokerageFee: {
        required: false,
        visible: false
      },
      attEstcoe: {
        required: true,
        visible: true
      },
      attFirpta: {
        required: true,
        visible: true
      },
      attFtb: {
        required: false,
        visible: false
      },
      attHarpta: {
        required: false,
        visible: false
      },
      attNewLoan: {
        required: true,
        visible: true
      },
      attOwnersExemption: {
        required: false,
        visible: false
      },
      attOwnersPolicySplit: {
        required: false,
        visible: false
      },
      attPersonalResidence: {
        required: true,
        visible: true
      },
      attReo: {
        required: false,
        visible: false
      },
      attSalesPrice: {
        required: true,
        visible: true
      },
      attSewerBill: {
        required: false,
        visible: false
      },
      attShortSale: {
        required: false,
        visible: false
      },
      attSubEscrow: {
        required: false,
        visible: false
      },
      attSurveyAmendmentSplit: {
        required: false,
        visible: false
      },
      attWaterBill: {
        required: false,
        visible: false
      },
      brokerCommissionListing: {
        required: false,
        visible: true
      },
      brokerCommissionSelling: {
        required: false,
        visible: true
      },
      brokerCommissionSplits: {
        required: true,
        visible: true
      },
      brokerCommissionTaxListing: {
        required: false,
        visible: false
      },
      brokerCommissionTaxSelling: {
        required: false,
        visible: false
      },
      brokerCommissionTaxTotal: {
        required: false,
        visible: false
      },
      brokerCommissionTierRate: {
        required: false,
        visible: false
      },
      brokerCommissionTotal: {
        required: false,
        visible: true
      },
      buyerCreditAmount: {
        required: false,
        visible: true
      },
      escrowBranch: {
        required: false,
        visible: false
      },
      escrowCity: {
        required: false,
        visible: false
      },
      escrowCounty: {
        required: true,
        visible: true
      },
      escrowState: {
        required: true,
        visible: true
      },
      hazardInsuranceAmount: {
        required: false,
        visible: true
      },
      homeownersDuesAmount: {
        required: false,
        visible: true
      },
      homeownersDuesDueDate: {
        required: false,
        visible: true
      },
      homeownersDuesPaid: {
        required: false,
        visible: false
      },
      homeownersDuesTerm: {
        required: false,
        visible: true
      },
      loanPrimaryAmount: {
        required: true,
        visible: true
      },
      loanPrimaryInterestRate: {
        required: false,
        visible: true
      },
      loanPrimaryOriginationFees: {
        required: false,
        visible: true
      },
      mortgagePayoffCount: {
        required: false,
        visible: false
      },
      mortgagePrimaryBalance: {
        required: false,
        visible: true
      },
      mortgagePrimaryInterestRate: {
        required: false,
        visible: true
      },
      mortgagePrimaryLienCloseDate: {
        required: false,
        visible: false
      },
      mortgagePrimaryPrepayPenalty: {
        required: false,
        visible: true
      },
      mortgageSecondaryBalance: {
        required: false,
        visible: true
      },
      mortgageSecondaryInterestRate: {
        required: false,
        visible: true
      },
      mortgageSecondaryPrepayPenalty: {
        required: false,
        visible: true
      },
      partyFirstName: {
        required: true,
        visible: true
      },
      partyLastName: {
        required: true,
        visible: true
      },
      partyType: {
        required: true,
        visible: true
      },
      propertyAddress: {
        required: false,
        visible: true
      },
      propertyCity: {
        required: true,
        visible: true
      },
      propertyCounty: {
        required: true,
        visible: true
      },
      propertyIncorporatedArea: {
        required: false,
        visible: false
      },
      propertyState: {
        required: true,
        visible: true
      },
      propertyZip: {
        required: false,
        visible: true
      },
      sellerResidentOfHawaii: {
        required: false,
        visible: false
      },
      taxes: {
        required: false,
        visible: true
      },
      taxesNextInstallment: {
        required: false,
        visible: false
      },
      taxesPaid: {
        required: false,
        visible: true
      },
      taxesPaidToDatePicker: {
        required: false,
        visible: false
      },
      taxesPaidToDateDropdown: {
        required: false,
        visible: false
      },
      taxesTerm: {
        required: false,
        visible: false
      },
      userCompany: {
        required: false,
        visible: true
      },
      userEmail: {
        required: false,
        visible: true
      },
      userFirstName: {
        required: false,
        visible: true
      },
      userLastName: {
        required: false,
        visible: true
      },
      userPhone: {
        required: false,
        visible: true
      },
      warrantyPremiumAmount: {
        required: false,
        visible: true
      }
    },
    WA: {
      attBrokerageFee: {
        required: false,
        visible: false
      },
      attEstcoe: {
        required: true,
        visible: true
      },
      attFirpta: {
        required: true,
        visible: true
      },
      attFtb: {
        required: false,
        visible: false
      },
      attHarpta: {
        required: false,
        visible: false
      },
      attNewLoan: {
        required: true,
        visible: true
      },
      attOwnersExemption: {
        required: false,
        visible: false
      },
      attOwnersPolicySplit: {
        required: false,
        visible: false
      },
      attPersonalResidence: {
        required: true,
        visible: true
      },
      attReo: {
        required: true,
        visible: true
      },
      attSalesPrice: {
        required: true,
        visible: true
      },
      attSewerBill: {
        required: false,
        visible: false
      },
      attShortSale: {
        required: true,
        visible: true
      },
      attSubEscrow: {
        required: false,
        visible: false
      },
      attSurveyAmendmentSplit: {
        required: false,
        visible: false
      },
      attWaterBill: {
        required: false,
        visible: false
      },
      brokerCommissionListing: {
        required: false,
        visible: true
      },
      brokerCommissionSelling: {
        required: false,
        visible: true
      },
      brokerCommissionSplits: {
        required: true,
        visible: true
      },
      brokerCommissionTaxListing: {
        required: false,
        visible: false
      },
      brokerCommissionTaxSelling: {
        required: false,
        visible: false
      },
      brokerCommissionTaxTotal: {
        required: false,
        visible: false
      },
      brokerCommissionTierRate: {
        required: false,
        visible: false
      },
      brokerCommissionTotal: {
        required: false,
        visible: true
      },
      buyerCreditAmount: {
        required: false,
        visible: true
      },
      escrowBranch: {
        required: false,
        visible: false
      },
      escrowCity: {
        required: true,
        visible: true
      },
      escrowCounty: {
        required: true,
        visible: true
      },
      escrowState: {
        required: true,
        visible: true
      },
      hazardInsuranceAmount: {
        required: false,
        visible: true
      },
      homeownersDuesAmount: {
        required: false,
        visible: true
      },
      homeownersDuesDueDate: {
        required: false,
        visible: true
      },
      homeownersDuesPaid: {
        required: false,
        visible: false
      },
      homeownersDuesTerm: {
        required: false,
        visible: true
      },
      loanPrimaryAmount: {
        required: true,
        visible: true
      },
      loanPrimaryInterestRate: {
        required: false,
        visible: true
      },
      loanPrimaryOriginationFees: {
        required: false,
        visible: true
      },
      mortgagePayoffCount: {
        required: false,
        visible: false
      },
      mortgagePrimaryBalance: {
        required: false,
        visible: true
      },
      mortgagePrimaryInterestRate: {
        required: false,
        visible: true
      },
      mortgagePrimaryLienCloseDate: {
        required: false,
        visible: false
      },
      mortgagePrimaryPrepayPenalty: {
        required: false,
        visible: true
      },
      mortgageSecondaryBalance: {
        required: false,
        visible: true
      },
      mortgageSecondaryInterestRate: {
        required: false,
        visible: true
      },
      mortgageSecondaryPrepayPenalty: {
        required: false,
        visible: true
      },
      partyFirstName: {
        required: true,
        visible: true
      },
      partyLastName: {
        required: true,
        visible: true
      },
      partyType: {
        required: true,
        visible: true
      },
      propertyAddress: {
        required: false,
        visible: true
      },
      propertyCity: {
        required: true,
        visible: true
      },
      propertyCounty: {
        required: true,
        visible: true
      },
      propertyIncorporatedArea: {
        required: false,
        visible: false
      },
      propertyState: {
        required: true,
        visible: true
      },
      propertyZip: {
        required: false,
        visible: true
      },
      sellerResidentOfHawaii: {
        required: false,
        visible: false
      },
      taxes: {
        required: false,
        visible: true
      },
      taxesNextInstallment: {
        required: false,
        visible: true
      },
      taxesPaid: {
        required: false,
        visible: false
      },
      taxesPaidToDatePicker: {
        required: false,
        visible: true
      },
      taxesPaidToDateDropdown: {
        required: false,
        visible: false
      },
      taxesTerm: {
        required: false,
        visible: true
      },
      userCompany: {
        required: false,
        visible: true
      },
      userEmail: {
        required: false,
        visible: true
      },
      userFirstName: {
        required: false,
        visible: true
      },
      userLastName: {
        required: false,
        visible: true
      },
      userPhone: {
        required: false,
        visible: true
      },
      warrantyPremiumAmount: {
        required: false,
        visible: true
      }
    }
  };

  getConfigByState(state: string): Observable<object> {
    return new Observable((observer) => {
      observer.next(this.config[state]);
      observer.complete();
    });
  }
}
