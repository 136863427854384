import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { find, includes } from 'lodash-es';

import { BranchModel } from './../../models/branch.model';
import { CityModel } from './../../models/city.model';
import { CountyModel } from './../../models/county.model';
import { StateModel } from './../../models/state.model';
import { NetsheetService } from './../../services/netsheet.service';
import { QuestionnaireConfigService } from './../../services/questionnaire-config.service';
import { GenericComponent } from './../generic/generic.component';

@Component({
  selector: 'nsc-transaction-location-information',
  templateUrl: './transaction-location-information.component.html',
  styleUrls: ['./transaction-location-information.component.scss']
})
export class TransactionLocationInformationComponent extends GenericComponent implements OnInit, OnChanges {
  @Input() form!: UntypedFormGroup;

  mainConfig: any = {};
  config: any = {
    propertyAddress: { visible: true },
    propertyState: { visible: true },
    propertyCounty: { visible: true },
    propertyCity: { visible: true },
    propertyZip: { visible: true },
    escrowState: { visible: true },
    escrowCounty: { visible: true },
    escrowCity: { visible: true },
    escrowBranch: { visible: true }
  };

  availableStates: StateModel[] = [];
  availableCounties: CountyModel[] = [];
  availableCities: CityModel[] = [];
  availableZips: string[] = [];
  availableEscrowCounties: CountyModel[] = [];
  availableEscrowCities: CityModel[] = [];
  availableEscrowBranches: BranchModel[] = [];

  availableCitiesFiltered: CityModel[] = [];
  availableZipsFiltered: string[] = [];

  get transactionInformation(): AbstractControl | null {
    return this.form?.get('transactionInformation');
  }

  constructor(
    private netsheetService: NetsheetService,
    private questionnaireConfigService: QuestionnaireConfigService
  ) {
    super();
  }

  ngOnInit(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;
    const county = this.transactionInformation?.get('propertyCounty')?.value;
    const city = this.transactionInformation?.get('propertyCity')?.value;
    const escrowState = this.transactionInformation?.get('escrowState')?.value;
    const escrowCounty = this.transactionInformation?.get('escrowCounty')?.value;

    this.addUniqueSubscription('load-states', this.getStatesLoadSubscription());
    this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
    this.addUniqueSubscription('load-property-counties', this.getCountiesLoadSubscription(state));
    this.addUniqueSubscription('load-escrow-counties', this.getEscrowCountiesLoadSubscription(escrowState));

    if (county) {
      this.addUniqueSubscription('load-property-cities', this.getCitiesLoadSubscription(state, county));

      if (city) {
        this.addUniqueSubscription('load-property-zips', this.getZipsLoadSubscription(state, county, city));
      }
    }

    if (escrowCounty) {
      this.addUniqueSubscription('load-escrow-cities', this.getEscrowCitiesLoadSubscription(escrowState, escrowCounty));
      this.addUniqueSubscription(
        'load-escrow-branches',
        this.getEscrowBranchesLoadSubscription(escrowState, escrowCounty)
      );
    }
  }

  onPropertyCityKeyUp(): void {
    const propertyCityInput = this.transactionInformation?.get('propertyCity');

    if (propertyCityInput) {
      this.availableCitiesFiltered = propertyCityInput.value
        ? this.citiesFilter(propertyCityInput.value)
        : [...this.availableCities];
    } else {
      this.availableCitiesFiltered = [...this.availableCities];
    }
  }

  onPropertyZipKeyUp(): void {
    const propertyZipInput = this.transactionInformation?.get('propertyZip');

    if (propertyZipInput) {
      this.availableZipsFiltered = propertyZipInput.value
        ? this.zipsFilter(propertyZipInput.value)
        : [...this.availableZips];
    } else {
      this.availableZipsFiltered = [...this.availableZips];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && this.form) {
      this.addUniqueSubscription(
        'property-state-change',
        this.transactionInformation?.get('propertyState')?.valueChanges.subscribe(() => {
          this.onUserPropertyStateUpdate();
        })
      );

      this.addUniqueSubscription(
        'property-county-change',
        this.transactionInformation?.get('propertyCounty')?.valueChanges.subscribe(() => {
          this.onUserPropertyCountyUpdate();
        })
      );

      this.addUniqueSubscription(
        'property-city-change',
        this.transactionInformation?.get('propertyCity')?.valueChanges.subscribe(() => {
          this.onUserPropertyCityUpdate();
        })
      );

      this.addUniqueSubscription(
        'escrow-state-change',
        this.transactionInformation?.get('escrowState')?.valueChanges.subscribe(() => {
          this.onUserEscrowStateUpdate();
        })
      );

      this.addUniqueSubscription(
        'escrow-county-change',
        this.transactionInformation?.get('escrowCounty')?.valueChanges.subscribe(() => {
          this.onUserEscrowCountyUpdate();
        })
      );
    }
  }

  onUserPropertyStateUpdate(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;

    this.availableCounties = [];
    this.availableCities = [];
    this.availableZips = [];
    this.availableEscrowCounties = [];
    this.availableEscrowCities = [];
    this.availableEscrowBranches = [];

    this.availableCitiesFiltered = [];
    this.availableZipsFiltered = [];

    this.transactionInformation?.patchValue(
      {
        propertyCounty: '',
        propertyCity: '',
        propertyZip: '',
        escrowState: state,
        escrowCounty: '',
        escrowCity: '',
        escrowBranch: ''
      },
      { emitEvent: false }
    );

    this.transactionInformation?.get('propertyCounty')?.disable({ emitEvent: false });
    this.transactionInformation?.get('propertyCity')?.disable({ emitEvent: false });
    this.transactionInformation?.get('propertyZip')?.disable({ emitEvent: false });
    this.transactionInformation?.get('escrowCounty')?.disable({ emitEvent: false });
    this.transactionInformation?.get('escrowCity')?.disable({ emitEvent: false });
    this.transactionInformation?.get('escrowBranch')?.disable({ emitEvent: false });

    this.transactionInformation?.get('propertyCounty')?.markAsUntouched();
    this.transactionInformation?.get('propertyCity')?.markAsUntouched();
    this.transactionInformation?.get('propertyZip')?.markAsUntouched();
    this.transactionInformation?.get('escrowCounty')?.markAsUntouched();
    this.transactionInformation?.get('escrowCity')?.markAsUntouched();
    this.transactionInformation?.get('escrowBranch')?.markAsUntouched();

    if (state) {
      this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
      this.addUniqueSubscription('load-property-counties', this.getCountiesLoadSubscription(state));
      this.addUniqueSubscription('load-escrow-counties', this.getEscrowCountiesLoadSubscription(state));
    }
  }

  onUserPropertyCountyUpdate(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;
    const county = this.transactionInformation?.get('propertyCounty')?.value;

    this.availableCities = [];
    this.availableZips = [];

    this.availableCitiesFiltered = [];
    this.availableZipsFiltered = [];

    this.transactionInformation?.patchValue(
      {
        propertyCity: '',
        propertyZip: ''
      },
      { emitEvent: false }
    );

    this.transactionInformation?.get('propertyCity')?.disable({ emitEvent: false });
    this.transactionInformation?.get('propertyZip')?.disable({ emitEvent: false });

    this.transactionInformation?.get('propertyCity')?.markAsUntouched();
    this.transactionInformation?.get('propertyZip')?.markAsUntouched();

    if (state && county) {
      this.addUniqueSubscription('load-property-cities', this.getCitiesLoadSubscription(state, county));
    }
  }

  onUserPropertyCityUpdate(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;
    const county = this.transactionInformation?.get('propertyCounty')?.value;
    const city = this.transactionInformation?.get('propertyCity')?.value;

    this.availableZips = [];
    this.availableZipsFiltered = [];

    this.transactionInformation?.patchValue(
      {
        propertyZip: ''
      },
      { emitEvent: false }
    );

    this.transactionInformation?.get('propertyZip')?.disable({ emitEvent: false });
    this.transactionInformation?.get('propertyZip')?.markAsUntouched();

    if (state && county && city) {
      this.addUniqueSubscription('load-property-zips', this.getZipsLoadSubscription(state, county, city));
    }
  }

  onUserEscrowStateUpdate(): void {
    const escrowState = this.transactionInformation?.get('escrowState')?.value;

    this.availableEscrowCounties = [];
    this.availableEscrowCities = [];
    this.availableEscrowBranches = [];

    this.transactionInformation?.patchValue(
      {
        escrowCounty: '',
        escrowCity: '',
        escrowBranch: ''
      },
      { emitEvent: false }
    );

    this.transactionInformation?.get('escrowCounty')?.disable({ emitEvent: false });
    this.transactionInformation?.get('escrowCity')?.disable({ emitEvent: false });
    this.transactionInformation?.get('escrowBranch')?.disable({ emitEvent: false });

    this.transactionInformation?.get('escrowCounty')?.markAsUntouched();
    this.transactionInformation?.get('escrowCity')?.markAsUntouched();
    this.transactionInformation?.get('escrowBranch')?.markAsUntouched();

    if (escrowState) {
      this.addUniqueSubscription('load-escrow-counties', this.getEscrowCountiesLoadSubscription(escrowState));
    }
  }

  onUserEscrowCountyUpdate(): void {
    const escrowState = this.transactionInformation?.get('escrowState')?.value;
    const escrowCounty = this.transactionInformation?.get('escrowCounty')?.value;

    this.availableEscrowCities = [];
    this.availableEscrowBranches = [];

    this.transactionInformation?.patchValue(
      {
        escrowCity: '',
        escrowBranch: ''
      },
      { emitEvent: false }
    );

    this.transactionInformation?.get('escrowCity')?.disable({ emitEvent: false });
    this.transactionInformation?.get('escrowBranch')?.disable({ emitEvent: false });

    this.transactionInformation?.get('escrowCity')?.markAsUntouched();
    this.transactionInformation?.get('escrowBranch')?.markAsUntouched();

    if (escrowState && escrowCounty) {
      this.addUniqueSubscription('load-escrow-cities', this.getEscrowCitiesLoadSubscription(escrowState, escrowCounty));
      this.addUniqueSubscription(
        'load-escrow-branches',
        this.getEscrowBranchesLoadSubscription(escrowState, escrowCounty)
      );
    }
  }

  getConfigLoadSubscription(state: string): Subscription {
    return this.questionnaireConfigService.getConfigByState(state).subscribe((response) => {
      this.mainConfig = response;

      this.onConfigUpdate(true);
    });
  }

  getStatesLoadSubscription(): Subscription {
    this.transactionInformation?.get('propertyState')?.disable({ emitEvent: false });

    return this.netsheetService.getStates().subscribe((response: StateModel[]) => {
      this.availableStates = response;
      this.transactionInformation?.get('propertyState')?.enable({ emitEvent: false });
    });
  }

  getCountiesLoadSubscription(state: string): Subscription {
    const county = this.transactionInformation?.get('propertyCounty')?.value;

    this.transactionInformation?.get('propertyCounty')?.disable({ emitEvent: false });

    return this.netsheetService.getCountiesByState(state).subscribe((response: CountyModel[]) => {
      this.availableCounties = response;

      if (county && !find(this.availableCounties, { value: county })) {
        this.transactionInformation?.get('propertyCounty')?.setValue('', { emitEvent: false });
      }

      this.transactionInformation?.get('propertyCounty')?.enable({ emitEvent: false });
    });
  }

  getCitiesLoadSubscription(state: string, county: string): Subscription {
    const city = this.transactionInformation?.get('propertyCity')?.value;

    this.transactionInformation?.get('propertyCity')?.disable({ emitEvent: false });

    return this.netsheetService.getCitiesByStateAndCounty(state, county).subscribe((response: CityModel[]) => {
      this.availableCities = response;
      this.availableCitiesFiltered = [...this.availableCities];

      if (city && !find(this.availableCities, { value: city })) {
        this.transactionInformation?.get('propertyCity')?.setValue('', { emitEvent: false });
      }

      this.transactionInformation?.get('propertyCity')?.enable({ emitEvent: false });
    });
  }

  getZipsLoadSubscription(state: string, county: string, city: string): Subscription {
    const zip = this.transactionInformation?.get('propertyZip')?.value;

    this.transactionInformation?.get('propertyZip')?.disable({ emitEvent: false });

    return this.netsheetService.getZipsByStateAndCountyAndCity(state, county, city).subscribe((response: string[]) => {
      this.availableZips = response;
      this.availableZipsFiltered = response.slice();

      if (zip && !includes(this.availableZips, zip)) {
        this.transactionInformation?.get('propertyZip')?.setValue('', { emitEvent: false });
      }

      this.transactionInformation?.get('propertyZip')?.enable({ emitEvent: false });
    });
  }

  getEscrowCountiesLoadSubscription(escrowState: string): Subscription {
    this.transactionInformation?.get('escrowCounty')?.disable({ emitEvent: false });

    return this.netsheetService.getCountiesByState(escrowState, true).subscribe((response: CountyModel[]) => {
      this.availableEscrowCounties = response;
      this.transactionInformation?.get('escrowCounty')?.enable({ emitEvent: false });
    });
  }

  getEscrowCitiesLoadSubscription(escrowState: string, escrowCounty: string): Subscription {
    this.transactionInformation?.get('escrowCity')?.disable({ emitEvent: false });

    return this.netsheetService
      .getCitiesByStateAndCounty(escrowState, escrowCounty, true)
      .subscribe((response: CityModel[]) => {
        this.availableEscrowCities = response;

        if (this.availableEscrowCities.length) {
          this.config.escrowCity = { visible: true, required: this.mainConfig.escrowCity.required };
        } else {
          this.config.escrowCity = { visible: false, required: false };
        }

        this.onConfigUpdate();

        this.transactionInformation?.get('escrowCity')?.enable({ emitEvent: false });
      });
  }

  getEscrowBranchesLoadSubscription(escrowState: string, escrowCounty: string): Subscription {
    this.transactionInformation?.get('escrowBranch')?.disable({ emitEvent: false });

    return this.netsheetService
      .getBranchesByStateAndCounty(escrowState, escrowCounty, true)
      .subscribe((response: BranchModel[]) => {
        this.availableEscrowBranches = response;

        if (this.availableEscrowBranches.length) {
          this.config.escrowBranch = { visible: true, required: this.mainConfig.escrowBranch.required };
        } else {
          this.config.escrowBranch = { visible: false, required: false };
        }

        this.onConfigUpdate();

        this.transactionInformation?.get('escrowBranch')?.enable({ emitEvent: false });
      });
  }

  onConfigUpdate(isMainConfigUpdated = false): void {
    for (const field of Object.keys(this.config)) {
      if (isMainConfigUpdated) {
        this.config[field] = this.mainConfig[field];

        if (['escrowCity', 'escrowBranch'].includes(field)) {
          this.config[field] = { visible: false, required: false };
        }
      }

      const permanentValidators: ValidatorFn[] = [];

      if (field === 'propertyZip') {
        permanentValidators.push(Validators.minLength(5), Validators.maxLength(5), Validators.pattern(/^[0-9]*$/));
      }

      if (this.config[field].required) {
        this.transactionInformation?.get(field)?.setValidators([...[Validators.required], ...permanentValidators]);
      } else if (permanentValidators.length) {
        this.transactionInformation?.get(field)?.setValidators(permanentValidators);
      } else {
        this.transactionInformation?.get(field)?.clearValidators();
      }
    }

    this.transactionInformation?.updateValueAndValidity({ emitEvent: false });
  }

  citiesFilter(name: string): CityModel[] {
    return this.availableCities.filter((option) => option.value.toLowerCase().includes(name.toLowerCase()));
  }

  zipsFilter(zip: number): string[] {
    return this.availableZips.filter((option) => option.toLowerCase().includes(('' + zip).toLowerCase()));
  }
}
