import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NetsheetCalculatorModule } from 'netsheet-calculator';

import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NetsheetCalculatorModule.forRoot({
      netsheet: {
        schema: 'https',
        domain: 'netsheet-stage.smartdirectre.com',
        port: 3003,
        version: 'v1'
      },
      report: {
        schema: 'https',
        domain: 'netcalcs-report-test.herokuapp.com',
        version: 'v2',
        securityHeader: 'NETCALCS-SECURITY-KEY',
        securityKey: '1F32EE2E7AEC53A74B3B67F977A69'
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
