<mat-card class="tw-mb-4" [hidden]="hideComponent" [formGroup]="form">
  <mat-card-content formGroupName="mortgages">
    <div class="tw-grid">
      <p class="tw-text-base tw-font-bold tw-mb-4">Existing Primary Mortgage</p>
      <hr class="tw-mb-4" />

      <mat-form-field [hidden]="!config.mortgagePrimaryBalance.visible" class="tw-mb-1">
        <mat-label>Balance Left on Primary Mortgage</mat-label>
        <input
          matInput
          formControlName="mortgagePrimaryBalance"
          nscRateAmount="amount"
          type="text"
          inputmode="decimal"
          placeholder="$"
          autocomplete="off"
          [required]="config.mortgagePrimaryBalance.required"
        />
        <mat-error *ngIf="mortgages?.get('mortgagePrimaryBalance')?.errors">
          <nsc-validation-error class="tw-block" [errors]="mortgages?.get('mortgagePrimaryBalance')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.mortgagePrimaryInterestRate.visible" class="tw-mb-1">
        <mat-label>Interest % on Primary Mortgage</mat-label>
        <input
          matInput
          formControlName="mortgagePrimaryInterestRate"
          nscRateAmount="rate"
          type="text"
          inputmode="decimal"
          placeholder="%"
          autocomplete="off"
          [required]="config.mortgagePrimaryInterestRate.required"
        />
        <mat-error *ngIf="mortgages?.get('mortgagePrimaryInterestRate')?.errors">
          <nsc-validation-error class="tw-block" [errors]="mortgages?.get('mortgagePrimaryInterestRate')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.mortgagePrimaryPrepayPenalty.visible" class="tw-mb-1">
        <mat-label>Prepayment Penalty</mat-label>
        <input
          matInput
          formControlName="mortgagePrimaryPrepayPenalty"
          nscRateAmount="amount"
          type="text"
          inputmode="decimal"
          placeholder="$"
          autocomplete="off"
          [required]="config.mortgagePrimaryPrepayPenalty.required"
        />
        <mat-error *ngIf="mortgages?.get('mortgagePrimaryPrepayPenalty')?.errors">
          <nsc-validation-error class="tw-block" [errors]="mortgages?.get('mortgagePrimaryPrepayPenalty')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.mortgagePrimaryLienCloseDate.visible" class="tw-mb-1">
        <mat-label>Existing Lien Close Date</mat-label>
        <input
          matInput
          formControlName="mortgagePrimaryLienCloseDate"
          readonly
          autocomplete="off"
          (click)="mortgagePrimaryLienCloseDate.open()"
          [matDatepicker]="mortgagePrimaryLienCloseDate"
          [max]="maxDate"
          [required]="config.mortgagePrimaryLienCloseDate.required"
        />
        <mat-datepicker #mortgagePrimaryLienCloseDate [touchUi]="isMobile">
          <mat-datepicker-actions>
            <button mat-button matDatepickerCancel>Cancel</button>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="mortgages?.get('mortgagePrimaryLienCloseDate')?.errors">
          <nsc-validation-error class="tw-block" [errors]="mortgages?.get('mortgagePrimaryLienCloseDate')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>

<mat-card [hidden]="hideComponent" [formGroup]="form">
  <mat-card-content formGroupName="mortgages">
    <div class="tw-grid">
      <p class="tw-text-base tw-font-bold tw-mb-4">Existing Secondary Mortgage</p>
      <hr class="tw-mb-4" />

      <mat-form-field [hidden]="!config.mortgageSecondaryBalance.visible" class="tw-mb-1">
        <mat-label>Balance Left on Secondary Mortgage</mat-label>
        <input
          matInput
          formControlName="mortgageSecondaryBalance"
          nscRateAmount="amount"
          type="text"
          inputmode="decimal"
          placeholder="$"
          autocomplete="off"
          [required]="config.mortgageSecondaryBalance.required"
        />
        <mat-error *ngIf="mortgages?.get('mortgageSecondaryBalance')?.errors">
          <nsc-validation-error class="tw-block" [errors]="mortgages?.get('mortgageSecondaryBalance')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.mortgageSecondaryInterestRate.visible" class="tw-mb-1">
        <mat-label>Interest % on Secondary Mortgage</mat-label>
        <input
          matInput
          formControlName="mortgageSecondaryInterestRate"
          nscRateAmount="rate"
          type="text"
          inputmode="decimal"
          placeholder="%"
          autocomplete="off"
          [required]="config.mortgageSecondaryInterestRate.required"
        />
        <mat-error *ngIf="mortgages?.get('mortgageSecondaryInterestRate')?.errors">
          <nsc-validation-error class="tw-block" [errors]="mortgages?.get('mortgageSecondaryInterestRate')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.mortgageSecondaryPrepayPenalty.visible" class="tw-mb-1">
        <mat-label>Prepayment Penalty</mat-label>
        <input
          matInput
          formControlName="mortgageSecondaryPrepayPenalty"
          nscRateAmount="amount"
          type="text"
          inputmode="decimal"
          placeholder="$"
          autocomplete="off"
          [required]="config.mortgageSecondaryPrepayPenalty.required"
        />
        <mat-error *ngIf="mortgages?.get('mortgageSecondaryPrepayPenalty')?.errors">
          <nsc-validation-error class="tw-block" [errors]="mortgages?.get('mortgageSecondaryPrepayPenalty')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
