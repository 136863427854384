<nsc-questionnaire
  *ngIf="!reportData"
  (calculateEvent)="calculateForm($event)"
  (resetEvent)="resetForm()"
  [errors]="errors"
  [form]="form"
  [formLoading]="formLoading"
></nsc-questionnaire>

<nsc-report *ngIf="reportData" (back)="onBack()" (export)="onExport($event)" [reportData]="reportData"></nsc-report>

<div class="tw-text-center tw-mt-2 tw-px-4">
  <p class="tw-text-xs">{{ version }}</p>
</div>
