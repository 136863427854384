import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  mobile: BehaviorSubject<boolean>;
  desktop: BehaviorSubject<boolean>;

  constructor() {
    this.mobile = new BehaviorSubject<boolean>(false);
    this.desktop = new BehaviorSubject<boolean>(true);
  }

  setDevice(device: 'mobile' | 'desktop'): void {
    if (device === 'mobile') {
      this.mobile.next(true);
      this.desktop.next(false);
    } else {
      this.mobile.next(false);
      this.desktop.next(true);
    }
  }

  isMobile(): Observable<boolean> {
    return this.mobile.asObservable();
  }

  isDesktop(): Observable<boolean> {
    return this.desktop.asObservable();
  }
}
