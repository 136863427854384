import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DeviceService } from './../../services/device.service';
import { QuestionnaireConfigService } from './../../services/questionnaire-config.service';
import { GenericComponent } from './../generic/generic.component';

import moment from 'moment';

@Component({
  selector: 'nsc-transaction-misc-information',
  templateUrl: './transaction-misc-information.component.html',
  styleUrls: ['./transaction-misc-information.component.scss']
})
export class TransactionMiscInformationComponent extends GenericComponent implements OnInit, OnChanges {
  @Input() form!: UntypedFormGroup;

  mainConfig: any = {};
  config: any = {
    attBrokerageFee: { visible: true },
    attEstcoe: { visible: true },
    attSalesPrice: { visible: true },
    attSewerBill: { visible: true },
    attWaterBill: { visible: true },
    buyerCreditAmount: { visible: true },
    hazardInsuranceAmount: { visible: true },
    loanPrimaryAmount: { visible: true },
    loanPrimaryInterestRate: { visible: true },
    loanPrimaryOriginationFees: { visible: true },
    mortgagePayoffCount: { visible: true },
    warrantyPremiumAmount: { visible: true }
  };

  isMobile = false;
  minDate = moment().toDate();
  buyerCreditAmountLabel = 'Credit to Buyer';

  get customerInformation(): AbstractControl | null {
    return this.form?.get('customerInformation');
  }

  get transactionInformation(): AbstractControl | null {
    return this.form?.get('transactionInformation');
  }

  constructor(private deviceService: DeviceService, private questionnaireConfigService: QuestionnaireConfigService) {
    super();
  }

  ngOnInit(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;

    this.subscriptions.push(
      this.deviceService.isMobile().subscribe((isMobile: boolean) => {
        this.isMobile = isMobile;
      })
    );

    this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && this.form) {
      this.addUniqueSubscription(
        'property-state-change',
        this.transactionInformation?.get('propertyState')?.valueChanges.subscribe((state: string) => {
          this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
        })
      );

      this.addUniqueSubscription(
        'party-type-change',
        this.customerInformation?.get('partyType')?.valueChanges.subscribe(() => {
          this.updateConfigByPartyType();
          this.onConfigUpdate(false);
        })
      );
    }
  }

  getConfigLoadSubscription(state: string): Subscription {
    return this.questionnaireConfigService.getConfigByState(state).subscribe((response) => {
      this.mainConfig = response;

      this.onConfigUpdate(true);
      this.updateConfigByPartyType();
      this.onConfigUpdate(false);
    });
  }

  updateConfigByPartyType(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;
    const partyType = this.customerInformation?.get('partyType')?.value;

    switch (partyType) {
      case 'B':
        this.buyerCreditAmountLabel = 'Credit from Seller';

        this.updateConfigByMainConfig([
          { key: 'attBrokerageFee', visible: true },
          { key: 'attSalesPrice', visible: true },
          { key: 'attSewerBill', visible: false },
          { key: 'attWaterBill', visible: false },
          { key: 'buyerCreditAmount', visible: true },
          { key: 'hazardInsuranceAmount', visible: true },
          { key: 'loanPrimaryAmount', visible: true },
          { key: 'loanPrimaryInterestRate', visible: true },
          { key: 'loanPrimaryOriginationFees', visible: true },
          { key: 'mortgagePayoffCount', visible: false },
          { key: 'warrantyPremiumAmount', visible: false }
        ]);

        break;
      case 'S':
        this.buyerCreditAmountLabel = state === 'TX' ? 'Seller Paid Closing Costs for Buyer' : 'Credit to Buyer';

        this.updateConfigByMainConfig([
          { key: 'attBrokerageFee', visible: true },
          { key: 'attSalesPrice', visible: true },
          { key: 'attSewerBill', visible: true },
          { key: 'attWaterBill', visible: true },
          { key: 'buyerCreditAmount', visible: true },
          { key: 'hazardInsuranceAmount', visible: false },
          { key: 'loanPrimaryAmount', visible: false },
          { key: 'loanPrimaryInterestRate', visible: false },
          { key: 'loanPrimaryOriginationFees', visible: false },
          { key: 'mortgagePayoffCount', visible: true },
          { key: 'warrantyPremiumAmount', visible: true }
        ]);

        break;
      case 'L':
        this.buyerCreditAmountLabel = 'Credit to Buyer';

        this.updateConfigByMainConfig([
          { key: 'attBrokerageFee', visible: false },
          { key: 'attSalesPrice', visible: false },
          { key: 'attSewerBill', visible: false },
          { key: 'attWaterBill', visible: false },
          { key: 'buyerCreditAmount', visible: false },
          { key: 'hazardInsuranceAmount', visible: false },
          { key: 'loanPrimaryAmount', visible: true },
          { key: 'loanPrimaryInterestRate', visible: true },
          { key: 'loanPrimaryOriginationFees', visible: true },
          { key: 'mortgagePayoffCount', visible: true },
          { key: 'warrantyPremiumAmount', visible: false }
        ]);

        break;
    }
  }

  updateConfigByMainConfig(config: any): void {
    for (const item of config) {
      if (this.mainConfig[item.key].visible) {
        if (item.visible) {
          this.config[item.key] = { visible: item.visible, required: this.mainConfig[item.key].required };
        } else {
          this.config[item.key] = { visible: item.visible, required: false };
        }
      }
    }
  }

  onConfigUpdate(isMainConfigUpdated = false): void {
    for (const field of Object.keys(this.config)) {
      if (isMainConfigUpdated) {
        this.config[field] = this.mainConfig[field];
      }

      if (this.config[field].required) {
        this.transactionInformation?.get(field)?.setValidators([Validators.required]);
      } else {
        this.transactionInformation?.get(field)?.clearValidators();
      }
    }

    this.transactionInformation?.updateValueAndValidity({ emitEvent: false });
  }
}
