<mat-card [formGroup]="form">
  <mat-card-content>
    <div class="tw-grid">
      <p class="tw-text-base tw-font-bold tw-mb-4">Miscellaneous Items</p>
      <hr class="tw-mb-4" />

      <div
        *ngFor="let misc of getMiscItemsFormGroups().controls; let i = index"
        formArrayName="miscItems"
        class="tw-mb-4"
      >
        <div [formGroupName]="i" class="tw-grid">
          <mat-radio-group formControlName="type" class="tw-flex tw-flex-col tw-mb-2">
            <mat-label class="tw-h-7 tw-leading-7">Type:</mat-label>
            <mat-radio-button class="tw-h-7 tw-leading-7" value="Credit">Credit</mat-radio-button>
            <mat-radio-button class="tw-h-7 tw-leading-7" value="Debit">Debit</mat-radio-button>
          </mat-radio-group>

          <mat-form-field class="tw-mb-1">
            <mat-label>Description</mat-label>
            <input
              matInput
              formControlName="description"
              type="text"
              inputmode="text"
              placeholder="Description"
              autocomplete="off"
            />
          </mat-form-field>

          <mat-form-field class="tw-mb-1">
            <mat-label>Amount</mat-label>
            <input
              matInput
              formControlName="amount"
              nscRateAmount="amount"
              type="text"
              inputmode="decimal"
              placeholder="$"
              autocomplete="off"
            />
          </mat-form-field>

          <div class="tw-text-right">
            <button
              mat-raised-button
              color="primary"
              type="button"
              (click)="removeMiscItem(i)"
              [disabled]="getMiscItemsFormGroups().length === 1"
            >
              Delete
            </button>
          </div>
        </div>
      </div>

      <div class="tw-text-right">
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="addMiscItem()"
          [disabled]="addMiscItemButtonDisable"
        >
          Add
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
