<mat-card [formGroup]="form">
  <mat-card-content formGroupName="transactionInformation">
    <p class="tw-text-base tw-font-bold tw-mb-4">Transaction Information</p>
    <hr class="tw-mb-4" />

    <nsc-transaction-location-information [form]="form"></nsc-transaction-location-information>
    <nsc-transaction-slider-information [form]="form"></nsc-transaction-slider-information>
    <nsc-transaction-misc-information [form]="form"></nsc-transaction-misc-information>
  </mat-card-content>
</mat-card>
