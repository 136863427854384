import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { GenericComponent } from './../generic/generic.component';

@Component({
  selector: 'nsc-misc-items',
  templateUrl: './misc-items.component.html',
  styleUrls: ['./misc-items.component.scss']
})
export class MiscItemsComponent extends GenericComponent implements OnChanges {
  @Input() form!: UntypedFormGroup;

  addMiscItemButtonDisable = true;

  get miscItems(): AbstractControl | null {
    return this.form?.get('miscItems');
  }

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && this.form) {
      this.addUniqueSubscription(
        'misc-items-change',
        this.miscItems?.valueChanges.subscribe((miscItems: any) => {
          this.addMiscItemButtonDisable = false;

          for (const miscItem of miscItems) {
            if (!miscItem.amount || !miscItem.description || !miscItem.type) {
              this.addMiscItemButtonDisable = true;
            }
          }
        })
      );
    }
  }

  addMiscItem(): void {
    this.getMiscItemsFormGroups().push(
      this.formBuilder.group({
        amount: [''],
        description: [''],
        type: ['Debit']
      })
    );
  }

  removeMiscItem(index: number): void {
    this.getMiscItemsFormGroups().removeAt(index);
  }

  getMiscItemsFormGroups(): UntypedFormArray {
    return this.miscItems as UntypedFormArray;
  }
}
