<div [formGroup]="form">
  <div class="tw-grid" formGroupName="transactionInformation">
    <mat-form-field [hidden]="!config.propertyAddress.visible" class="tw-mb-1">
      <mat-label>Property Address</mat-label>
      <input
        matInput
        formControlName="propertyAddress"
        type="text"
        inputmode="text"
        placeholder="Property Address"
        autocomplete="none"
        [required]="config.propertyAddress.required"
      />
      <mat-error *ngIf="transactionInformation?.get('propertyAddress')?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.get('propertyAddress')?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.propertyState.visible" class="tw-mb-1">
      <mat-label>Property State</mat-label>
      <mat-select formControlName="propertyState" [required]="config.propertyState.required">
        <mat-option *ngFor="let state of availableStates" [value]="state.value">
          {{ state.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="transactionInformation?.get('propertyState')?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.get('propertyState')?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.propertyCounty.visible" class="tw-mb-1">
      <mat-label>Property County</mat-label>
      <mat-select formControlName="propertyCounty" [required]="config.propertyCounty.required">
        <mat-option *ngFor="let county of availableCounties" [value]="county.value">
          {{ county.name }}
        </mat-option>
      </mat-select>

      <mat-error *ngIf="transactionInformation?.get('propertyCounty')?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.get('propertyCounty')?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.propertyCity.visible" class="tw-mb-1">
      <mat-label>Property City</mat-label>
      <input
        matInput
        formControlName="propertyCity"
        type="text"
        inputmode="text"
        autocomplete="none"
        [matAutocomplete]="city"
        [required]="config.propertyCity.required"
        (keyup)="onPropertyCityKeyUp()"
      />
      <mat-autocomplete #city="matAutocomplete">
        <mat-option *ngFor="let city of availableCitiesFiltered" [value]="city.value">
          {{ city.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="transactionInformation?.get('propertyCity')?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.get('propertyCity')?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.propertyZip.visible" class="tw-mb-1">
      <mat-label>Property Zip</mat-label>
      <input
        #propertyZip
        matInput
        formControlName="propertyZip"
        type="text"
        inputmode="decimal"
        autocomplete="none"
        [matAutocomplete]="zip"
        [required]="config.propertyZip.required"
        (keyup)="onPropertyZipKeyUp()"
      />
      <mat-hint align="end">{{ propertyZip.value.length }} / 5</mat-hint>
      <mat-autocomplete #zip="matAutocomplete">
        <mat-option *ngFor="let zip of availableZipsFiltered" [value]="zip">
          {{ zip }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="transactionInformation?.get('propertyZip')?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.get('propertyZip')?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.escrowState.visible" class="tw-mb-1">
      <mat-label>Escrow Location (State)</mat-label>
      <mat-select formControlName="escrowState" [required]="config.escrowState.required">
        <mat-option *ngFor="let state of availableStates" [value]="state.value">
          {{ state.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="transactionInformation?.get('escrowState')?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.get('escrowState')?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.escrowCounty.visible" class="tw-mb-1">
      <mat-label>Escrow Location (County)</mat-label>
      <mat-select formControlName="escrowCounty" [required]="config.escrowCounty.required">
        <mat-option *ngFor="let county of availableEscrowCounties" [value]="county.value">
          {{ county.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="transactionInformation?.get('escrowCounty')?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.get('escrowCounty')?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.escrowCity.visible" class="tw-mb-1">
      <mat-label>Escrow Location (City)</mat-label>
      <mat-select formControlName="escrowCity" [required]="config.escrowCity.required">
        <mat-option *ngFor="let city of availableEscrowCities" [value]="city.value">
          {{ city.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="transactionInformation?.get('escrowCity')?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.get('escrowCity')?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.escrowBranch.visible" class="tw-mb-1">
      <mat-label>Escrow Location (Branch)</mat-label>
      <mat-select formControlName="escrowBranch" [required]="config.escrowBranch.required">
        <mat-option *ngFor="let branch of availableEscrowBranches" [value]="branch.value">
          {{ branch.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="transactionInformation?.get('escrowBranch')?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.get('escrowBranch')?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>
  </div>
</div>
