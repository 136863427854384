<mat-card [formGroup]="form">
  <mat-card-content formGroupName="customerInformation">
    <div class="tw-grid">
      <p class="tw-text-base tw-font-bold tw-mb-4">Your Customer Information</p>
      <hr class="tw-mb-4" />

      <mat-radio-group
        [hidden]="!config.partyType.visible"
        formControlName="partyType"
        class="tw-flex tw-flex-col tw-mb-2"
      >
        <mat-label class="tw-h-7 tw-leading-7">This Netsheet is for a:</mat-label>
        <mat-radio-button class="tw-h-7 tw-leading-7" value="L">Borrower</mat-radio-button>
        <mat-radio-button class="tw-h-7 tw-leading-7" value="B">Buyer</mat-radio-button>
        <mat-radio-button class="tw-h-7 tw-leading-7" value="S">Seller</mat-radio-button>
      </mat-radio-group>

      <mat-form-field [hidden]="!config.partyFirstName.visible" class="tw-mb-1">
        <mat-label>First Name</mat-label>
        <input
          matInput
          formControlName="partyFirstName"
          type="text"
          inputmode="text"
          placeholder="First Name"
          autocomplete="off"
          [required]="config.partyFirstName.required"
        />
        <mat-error *ngIf="customerInformation?.get('partyFirstName')?.errors">
          <nsc-validation-error class="tw-block" [errors]="customerInformation?.get('partyFirstName')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.partyLastName.visible" class="tw-mb-1">
        <mat-label>Last Name</mat-label>
        <input
          matInput
          formControlName="partyLastName"
          type="text"
          inputmode="text"
          placeholder="Last Name"
          autocomplete="off"
          [required]="config.partyLastName.required"
        />
        <mat-error *ngIf="customerInformation?.get('partyLastName')?.errors">
          <nsc-validation-error class="tw-block" [errors]="customerInformation?.get('partyLastName')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
